import React from 'react'

const Logo = ({w,h}) => {
  return (
    <svg 
	 width={w} height={h} viewBox="0 0 200 200" enable-background="new 0 0 200 200" >
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M1.000000,43.000000 
	C1.000000,29.021517 1.000000,15.043034 1.000000,1.032275 
	C67.542679,1.032275 134.085373,1.032275 201.000000,1.032275 
	C201.000000,15.018702 201.000000,29.040693 200.602463,43.586063 
	C199.415802,44.370274 198.644852,44.745457 197.834747,44.874138 
	C190.218430,46.083939 189.248352,47.365234 189.967194,54.977154 
	C190.300308,58.504475 190.432770,62.050743 190.661407,65.671196 
	C189.549805,65.809418 188.903137,65.954865 188.255371,65.960068 
	C183.176468,66.000862 178.045868,65.593307 173.038910,66.205292 
	C170.317780,66.537880 166.761826,67.919716 165.422577,70.008278 
	C162.783600,74.123718 160.801208,75.035988 156.914200,71.689529 
	C154.336761,69.470543 151.101685,66.404640 148.224258,66.470764 
	C141.662460,66.621567 134.600800,64.214836 128.457413,68.667854 
	C127.990250,69.006477 127.143242,69.027161 126.522926,68.915962 
	C121.352585,67.989082 116.194016,66.996529 110.581055,66.015251 
	C108.475601,65.994789 106.766670,66.244423 105.178108,65.914490 
	C102.211319,65.298294 98.055275,65.254562 96.667053,63.331669 
	C94.245895,59.977982 92.764587,57.225479 87.852600,58.426239 
	C84.110344,59.341049 80.566353,59.935135 78.602509,64.037674 
	C73.509331,64.380386 68.819984,64.748734 64.125374,65.028839 
	C58.770233,65.348366 53.384403,65.353065 48.060879,65.939301 
	C43.827259,66.405510 41.537888,64.154610 42.069740,59.405582 
	C42.418751,56.289196 42.640930,53.158604 43.206245,49.807743 
	C42.786144,48.026478 42.470383,45.602985 41.301285,45.077316 
	C37.535000,43.383835 33.459755,42.371449 29.481474,41.169811 
	C29.230190,41.093914 28.789511,41.645054 28.601006,41.783760 
	C29.472122,43.318645 30.241795,44.674789 30.552643,46.034061 
	C26.728466,46.028034 23.363115,46.018879 19.945469,45.615765 
	C18.415157,44.481525 16.968832,43.190060 15.453267,43.102928 
	C10.649417,42.826748 5.819710,43.000324 1.000000,43.000000 
z"/>
<path fill="#FDFDFC" opacity="1.000000" stroke="none" 
	d="
M76.000000,201.000000 
	C70.645073,201.000000 65.290138,201.000000 59.478539,200.571228 
	C57.447651,194.644150 56.095215,189.067749 54.198711,183.682968 
	C53.345734,181.261124 51.406059,179.222015 49.839218,176.782257 
	C49.716129,176.219666 49.713295,175.884033 49.750904,175.260071 
	C49.596149,174.652328 49.400955,174.332932 49.105881,173.688965 
	C48.971310,172.914368 48.936619,172.464355 49.205849,171.761246 
	C49.305363,169.127304 49.112179,166.745438 48.894371,164.365814 
	C48.303543,157.910889 44.892185,151.663910 47.178528,145.019821 
	C48.136631,142.235596 49.700726,139.659897 51.352440,136.953430 
	C53.446335,135.148056 56.803177,133.136414 56.582890,131.657654 
	C56.059872,128.146698 57.679588,123.784676 53.650021,121.020424 
	C52.651951,120.335747 52.218334,118.391739 52.074677,116.971046 
	C51.313683,109.445198 50.590446,101.908630 50.205292,94.357872 
	C50.133972,92.959671 51.671780,91.479393 52.466915,90.036987 
	C52.981316,90.248444 53.495712,90.459900 54.010113,90.671349 
	C54.010113,94.273392 53.627205,97.927650 54.109520,101.463913 
	C54.679474,105.642700 54.437031,110.416451 59.092255,112.908340 
	C59.074074,112.923325 59.083668,112.886078 59.098160,113.111160 
	C59.213276,113.640892 59.410511,113.857620 59.826752,114.299393 
	C60.037197,115.051674 60.125240,115.490974 59.899780,115.971786 
	C59.222446,116.438248 58.858616,116.863205 58.494785,117.288155 
	C59.324238,117.252403 60.153690,117.216660 61.203297,117.181778 
	C61.423450,117.182632 61.858402,117.114151 61.919373,117.077751 
	C61.980343,117.041351 61.879200,117.141098 61.890129,117.441788 
	C62.324226,118.118103 62.747395,118.493721 63.351082,119.039505 
	C63.793839,119.444389 63.929661,119.737656 63.797379,120.386795 
	C64.046997,121.806511 64.438316,122.928925 65.022476,124.341019 
	C67.737244,127.805191 70.259155,130.979675 72.834549,134.532532 
	C72.961952,135.927963 73.035858,136.945023 73.041756,138.388641 
	C73.274216,141.242447 73.574684,143.669708 73.874748,146.411346 
	C73.900208,147.144211 73.926071,147.562683 73.951454,148.388016 
	C74.591827,152.687988 75.762108,156.580185 75.768829,160.474411 
	C75.785378,170.060150 75.212898,179.645691 75.017982,189.234177 
	C74.986084,190.803207 75.670265,192.386795 75.990417,194.402664 
	C75.971565,196.894455 75.985786,198.947220 76.000000,201.000000 
z"/>
<path fill="#FDFC12" opacity="1.000000" stroke="none" 
	d="
M1.000000,115.000000 
	C1.000000,92.979103 1.000000,70.958214 1.440944,48.521973 
	C10.511726,48.433056 19.139141,48.870453 27.771992,49.058907 
	C39.577412,49.316605 39.411346,49.232075 39.175602,60.919682 
	C39.088196,65.253151 37.222790,66.407455 33.344532,66.165955 
	C29.364744,65.918152 25.365597,65.991341 21.375708,65.892380 
	C18.523468,65.821640 17.126127,67.020103 17.081863,70.027924 
	C16.871666,84.310860 16.557383,98.592270 16.193947,113.345146 
	C16.006603,117.977661 15.905477,122.139244 15.696348,126.311996 
	C15.588346,126.323166 15.265558,126.603378 14.959263,126.366348 
	C11.652535,126.220596 10.769369,124.698120 10.949003,121.841537 
	C11.137836,118.838692 11.018746,115.724136 6.772853,114.763084 
	C4.701158,114.657280 2.850579,114.828636 1.000000,115.000000 
z"/>
<path fill="#B4FA10" opacity="1.000000" stroke="none" 
	d="
M201.000000,49.000000 
	C201.000000,72.020897 201.000000,95.041786 200.616913,118.548828 
	C198.846512,120.054520 197.459183,121.074066 196.030304,122.062805 
	C195.988754,122.031990 196.082794,122.072945 195.714294,122.048004 
	C192.966934,122.812195 191.151199,122.613686 189.496582,120.213554 
	C188.077255,118.154770 185.853271,116.369095 183.358078,119.900620 
	C179.237122,122.144440 175.676254,125.723831 170.997894,121.562469 
	C169.827835,120.521698 168.348969,119.828110 166.718246,118.809029 
	C165.601700,117.108490 164.778915,115.573875 163.873199,113.808960 
	C163.608383,113.289413 163.354294,113.094116 162.963409,112.608658 
	C159.793503,103.263336 159.178772,94.382790 161.745026,84.993019 
	C164.202866,75.999893 170.673126,69.825516 179.998489,70.002457 
	C183.592056,70.070641 187.266754,70.553627 190.732468,71.486595 
	C195.120575,72.667870 196.218292,70.756447 195.951981,66.995361 
	C195.672562,63.049187 195.390015,59.098534 194.927216,55.171246 
	C194.374023,50.476856 196.046951,48.176399 201.000000,49.000000 
M197.368057,97.067749 
	C196.746948,94.507141 196.437393,91.816566 195.435486,89.414818 
	C193.709351,85.276924 187.720993,81.715492 183.828156,82.262466 
	C179.408554,82.883453 176.110535,85.628845 175.636719,89.806198 
	C175.058060,94.907906 174.932114,100.419655 176.271896,105.297737 
	C178.379822,112.972626 188.649353,115.207291 193.760666,109.108650 
	C196.130722,106.280785 196.377167,101.673103 197.368057,97.067749 
z"/>
<path fill="#FBF9F6" opacity="1.000000" stroke="none" 
	d="
M110.000000,201.000000 
	C106.307091,201.000000 102.614182,201.000000 98.415634,200.602386 
	C96.942711,195.916153 95.982956,191.625854 95.006294,187.339386 
	C93.686836,181.548401 92.354393,175.760376 91.026733,169.595734 
	C90.356300,163.472137 89.686653,157.723877 89.010727,151.647034 
	C89.001122,150.880600 88.997803,150.442749 88.996529,149.554138 
	C89.983185,142.736450 90.967789,136.369492 91.979034,129.622223 
	C93.728271,127.895142 95.450867,126.548378 97.550484,125.143578 
	C100.642113,124.368515 103.356728,123.651489 106.233185,123.242126 
	C104.694618,128.027252 104.797020,131.724792 110.647156,133.316650 
	C112.177559,135.116013 113.546524,136.604401 114.716415,138.411560 
	C113.930862,140.540756 113.679436,142.563538 112.692719,144.120285 
	C110.120811,148.177979 110.608757,151.736572 113.773193,155.549103 
	C113.577766,157.945389 113.325859,159.951981 113.045944,162.318985 
	C112.951073,163.398468 112.884216,164.117523 112.636978,164.980927 
	C112.218422,165.335083 112.051834,165.591446 111.726860,165.922394 
	C111.496857,165.950455 111.036774,166.005936 110.743835,165.963776 
	C109.992050,165.615112 109.533211,165.308640 108.873520,164.716187 
	C104.720207,163.399689 104.626762,167.139297 104.479805,168.835373 
	C104.183762,172.251938 107.264420,172.085846 110.112442,172.200409 
	C110.733711,172.248184 110.983223,172.168839 111.498215,172.091339 
	C112.113663,172.117279 112.463638,172.141357 112.888474,172.403503 
	C112.963341,172.641556 113.104759,173.120193 112.899345,173.475403 
	C112.437759,177.265823 112.181587,180.701050 111.883461,184.253113 
	C111.841499,184.369965 111.853111,184.617996 111.525391,184.904282 
	C110.798447,190.460373 110.399223,195.730194 110.000000,201.000000 
z"/>
<path fill="#FAF5CD" opacity="1.000000" stroke="none" 
	d="
M16.280163,112.874222 
	C16.557383,98.592270 16.871666,84.310860 17.081863,70.027924 
	C17.126127,67.020103 18.523468,65.821640 21.375708,65.892380 
	C25.365597,65.991341 29.364744,65.918152 33.344532,66.165955 
	C37.222790,66.407455 39.088196,65.253151 39.175602,60.919682 
	C39.411346,49.232075 39.577412,49.316605 27.771992,49.058907 
	C19.139141,48.870453 10.511726,48.433056 1.440944,48.053314 
	C1.000000,47.555553 1.000000,47.111111 1.468270,46.332130 
	C7.956949,46.001640 13.977357,46.005680 19.997765,46.009724 
	C23.363115,46.018879 26.728466,46.028034 30.965765,46.036034 
	C35.531776,47.368046 39.225842,48.701210 42.919907,50.034374 
	C42.640930,53.158604 42.418751,56.289196 42.069740,59.405582 
	C41.537888,64.154610 43.827259,66.405510 48.060879,65.939301 
	C53.384403,65.353065 58.770233,65.348366 64.125374,65.028839 
	C68.819984,64.748734 73.509331,64.380386 78.980240,64.040695 
	C80.845802,64.424583 81.932144,64.818802 82.692749,65.410957 
	C76.924995,67.121590 71.482979,68.634277 65.956093,69.803467 
	C62.560192,65.641533 58.124268,67.019714 53.835167,66.698669 
	C53.007675,66.902802 52.359062,67.301552 51.402626,67.758064 
	C50.398464,68.213203 49.702118,68.610573 48.656090,69.032578 
	C47.188580,69.403557 46.070747,69.749893 44.763165,70.022430 
	C44.573414,69.948631 44.189320,69.813446 43.936668,69.549026 
	C41.356670,69.338211 39.029327,69.391815 36.496346,69.504990 
	C36.290707,69.564545 35.916229,69.772163 35.511208,69.711647 
	C33.738380,69.654099 32.370579,69.657066 30.792337,69.473022 
	C29.964853,70.197647 29.347809,71.109306 28.741920,72.446320 
	C28.786018,74.575554 28.818962,76.279427 28.657396,78.152206 
	C28.658131,78.879799 28.853374,79.438477 29.043343,80.413010 
	C29.297474,83.546448 29.556883,86.264038 29.595299,89.246391 
	C29.620565,91.001495 29.866823,92.491837 30.106899,94.386139 
	C30.509832,97.219261 30.918947,99.648415 31.262672,102.427139 
	C31.285366,103.475540 31.373451,104.174362 31.314526,105.285683 
	C31.423567,108.809227 31.679615,111.920288 31.921234,115.281067 
	C31.950964,115.691597 31.995121,115.852409 32.047188,116.416443 
	C32.259640,119.155960 32.464180,121.492249 32.457130,124.099945 
	C32.519089,125.599243 32.792637,126.827118 33.030483,128.299454 
	C32.994785,128.543915 32.887760,129.026306 32.585712,129.221436 
	C31.866009,131.265457 31.448353,133.114334 30.771620,135.210632 
	C28.250402,139.355942 27.415895,143.323776 29.092360,147.775925 
	C29.826601,149.725830 29.828064,152.004395 29.899557,154.142517 
	C30.342026,167.375031 30.647247,180.612549 31.181719,193.841003 
	C31.248148,195.485153 32.399361,197.085480 33.236351,198.959732 
	C33.616524,199.808899 33.808262,200.404449 34.000000,201.000000 
	C31.299986,201.000000 28.599974,201.000000 25.497602,200.535797 
	C25.152887,195.128998 25.210529,190.186386 25.494289,184.878967 
	C25.771175,172.343063 25.821943,160.171967 25.904799,147.623840 
	C25.196266,145.141037 24.455647,143.035309 23.744053,140.598419 
	C23.469189,139.204697 23.165298,138.142090 23.284756,137.057846 
	C25.308491,136.058014 27.947544,135.366409 28.311577,134.047089 
	C29.341473,130.314560 30.053774,126.283752 29.796648,122.443733 
	C28.760303,106.966545 27.417234,91.506638 25.907862,76.067680 
	C25.663170,73.564781 24.004492,71.200111 22.999399,68.771553 
	C22.254875,68.928947 21.510349,69.086334 20.765823,69.243729 
	C20.503965,71.220268 20.043112,73.193695 20.012047,75.173851 
	C19.784204,89.697929 19.642420,104.223351 19.270611,118.442276 
	C18.138531,116.382210 17.209347,114.628220 16.280163,112.874222 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M161.000000,201.000000 
	C155.311966,201.000000 149.623917,201.000000 143.467941,200.563385 
	C143.000000,198.335464 143.000000,196.544174 143.000000,194.408157 
	C137.443329,194.301254 136.358322,191.134827 136.975464,186.721619 
	C137.134796,185.582123 137.065140,184.397034 136.988297,183.240372 
	C136.626740,177.797989 138.109543,173.531403 144.253906,171.997971 
	C144.677063,171.992615 144.846313,171.989899 145.458038,171.988510 
	C148.266266,171.991440 150.632050,171.993057 153.168030,172.224091 
	C153.891159,171.973602 154.444092,171.493683 155.425674,171.010345 
	C157.569717,171.005356 159.285141,171.003769 161.042892,171.371765 
	C164.055954,177.589966 162.763092,183.645721 161.542969,189.532822 
	C160.934341,192.469467 161.788193,193.103165 164.256409,193.451752 
	C164.199249,194.885895 164.165955,195.929276 163.762939,197.013672 
	C162.595490,198.369781 161.797745,199.684891 161.000000,201.000000 
z"/>
<path fill="#FCD1B2" opacity="1.000000" stroke="none" 
	d="
M19.000000,201.000000 
	C14.643101,201.000000 10.286201,201.000000 5.467752,200.565018 
	C5.666232,196.068924 6.326261,192.007812 7.343439,187.900452 
	C8.079154,187.382797 8.798825,186.901184 8.785082,186.441498 
	C8.581375,179.627792 8.280438,172.816986 8.379616,166.046417 
	C9.958953,166.346191 11.161189,166.605042 12.422193,166.876526 
	C12.957040,164.663071 13.380093,162.922745 13.797688,161.181107 
	C14.215940,159.436768 14.628743,157.691086 15.418085,155.951965 
	C16.858055,153.971664 17.923874,151.985413 19.001093,149.596619 
	C19.180973,147.848038 19.349453,146.502014 19.669334,143.946381 
	C21.345541,145.972443 22.191317,146.994751 23.029385,148.415039 
	C23.311518,151.161484 23.601360,153.509979 23.524529,155.978546 
	C22.776302,156.778183 22.107832,157.436417 22.055540,158.140442 
	C20.994661,172.423813 20.004402,186.712433 19.000000,201.000000 
z"/>
<path fill="#FEFFFE" opacity="1.000000" stroke="none" 
	d="
M201.000000,149.000000 
	C201.000000,166.204117 201.000229,183.408249 200.995499,200.612366 
	C200.995468,200.741592 200.927582,200.870789 200.891373,201.000000 
	C197.582886,201.000000 194.274399,201.000000 190.483307,200.531525 
	C189.999695,188.824936 189.823730,177.582565 190.097046,166.351120 
	C190.187012,162.654251 191.516876,158.997696 192.091248,155.293457 
	C192.198853,154.599518 191.410400,153.766632 191.149780,152.717957 
	C191.882721,151.635742 192.501343,150.833420 193.472870,150.070984 
	C196.217178,149.740555 198.608582,149.370270 201.000000,149.000000 
z"/>
<path fill="#FBDDC2" opacity="1.000000" stroke="none" 
	d="
M15.043936,155.945999 
	C14.628743,157.691086 14.215940,159.436768 13.797688,161.181107 
	C13.380093,162.922745 12.957040,164.663071 12.422193,166.876526 
	C11.161189,166.605042 9.958953,166.346191 8.334304,165.724792 
	C7.269916,164.907852 6.627939,164.453476 5.739093,163.786865 
	C4.677886,159.451324 6.847431,154.169937 1.000000,152.000000 
	C1.000000,146.311966 1.000000,140.623917 1.312042,134.268997 
	C2.423750,131.411194 3.223417,129.220276 4.023084,127.029358 
	C4.388855,127.009689 4.754626,126.990021 5.120397,126.970352 
	C5.746063,129.313293 6.371728,131.656235 6.759227,134.204987 
	C8.609477,136.633331 10.697894,138.855865 12.804720,141.375488 
	C12.851905,142.068390 12.880681,142.464203 12.626165,142.942596 
	C12.567809,143.670151 12.792744,144.315125 13.046381,145.305542 
	C13.705088,147.104538 14.335092,148.558090 14.968500,150.436478 
	C14.995915,152.556198 15.019926,154.251099 15.043936,155.945999 
z"/>
<path fill="#E6FCE5" opacity="1.000000" stroke="none" 
	d="
M111.031891,66.023804 
	C116.194016,66.996529 121.352585,67.989082 126.522926,68.915962 
	C127.143242,69.027161 127.990250,69.006477 128.457413,68.667854 
	C134.600800,64.214836 141.662460,66.621567 148.224258,66.470764 
	C151.101685,66.404640 154.336761,69.470543 156.914200,71.689529 
	C160.801208,75.035988 162.783600,74.123718 165.422577,70.008278 
	C166.761826,67.919716 170.317780,66.537880 173.038910,66.205292 
	C178.045868,65.593307 183.176468,66.000862 188.255371,65.960068 
	C188.903137,65.954865 189.549805,65.809418 190.661407,65.671196 
	C190.432770,62.050743 190.300308,58.504475 189.967194,54.977154 
	C189.248352,47.365234 190.218430,46.083939 197.834747,44.874138 
	C198.644852,44.745457 199.415802,44.370274 200.602463,44.054722 
	C201.000000,44.750000 201.000000,45.500000 200.570587,46.624130 
	C192.897400,47.212566 192.846222,47.262093 192.961380,54.355358 
	C193.020813,58.016979 193.009766,61.682343 193.183975,65.338707 
	C193.333542,68.477791 192.067291,69.671974 188.994858,68.872490 
	C176.140213,65.527580 166.520432,69.714249 159.838593,81.055283 
	C158.735016,79.534561 157.875275,78.258789 156.978455,76.653183 
	C156.019028,75.486465 155.096664,74.649574 154.061462,73.598862 
	C153.738403,73.123154 153.470688,72.950279 153.043869,72.528473 
	C146.871994,67.220482 135.535782,66.217003 127.970367,72.973831 
	C127.996384,72.998741 127.968140,72.932487 127.680893,72.947495 
	C126.932220,73.253525 126.470795,73.544540 125.662598,73.764145 
	C123.889008,69.616798 120.635406,68.937050 116.869843,69.041618 
	C114.575539,69.105331 112.275131,68.949432 110.010735,68.584778 
	C110.373291,67.526192 110.702583,66.774994 111.031891,66.023804 
z"/>
<path fill="#B4EF65" opacity="1.000000" stroke="none" 
	d="
M160.082428,81.300224 
	C166.520432,69.714249 176.140213,65.527580 188.994858,68.872490 
	C192.067291,69.671974 193.333542,68.477791 193.183975,65.338707 
	C193.009766,61.682343 193.020813,58.016979 192.961380,54.355358 
	C192.846222,47.262093 192.897400,47.212566 200.570587,46.999130 
	C201.000000,47.444447 201.000000,47.888889 201.000000,48.666668 
	C196.046951,48.176399 194.374023,50.476856 194.927216,55.171246 
	C195.390015,59.098534 195.672562,63.049187 195.951981,66.995361 
	C196.218292,70.756447 195.120575,72.667870 190.732468,71.486595 
	C187.266754,70.553627 183.592056,70.070641 179.998489,70.002457 
	C170.673126,69.825516 164.202866,75.999893 161.745026,84.993019 
	C159.178772,94.382790 159.793503,103.263336 163.049622,112.836586 
	C163.381058,113.736671 163.632965,113.933540 163.956146,114.039261 
	C164.778915,115.573875 165.601700,117.108490 166.712311,119.158127 
	C166.986588,120.137726 166.973053,120.602303 166.623260,121.014854 
	C164.278397,118.572792 162.269821,116.182762 160.224213,113.383652 
	C157.649536,102.424744 156.797668,91.869324 160.082428,81.300224 
z"/>
<path fill="#FD9358" opacity="1.000000" stroke="none" 
	d="
M50.000000,201.000000 
	C47.629444,201.000000 45.258884,201.000000 42.424255,200.701965 
	C41.640224,199.936066 41.320267,199.468216 41.000076,198.602203 
	C40.991123,197.142349 40.982399,196.080643 40.983307,194.558929 
	C40.333569,190.063263 39.674198,186.027603 39.013992,181.620636 
	C39.017067,180.507401 39.020981,179.765518 39.337540,178.976227 
	C40.102974,177.779251 40.555767,176.629654 41.011215,175.484619 
	C42.007446,175.993378 43.001019,176.497574 43.997223,177.000885 
	C43.999847,177.000000 43.997955,177.005402 44.017151,177.304291 
	C44.362743,178.067383 44.689144,178.531586 45.052250,179.358887 
	C45.757130,181.021576 46.768436,182.255081 47.033699,183.632263 
	C48.144276,189.398041 49.031162,195.206909 50.000000,201.000000 
z"/>
<path fill="#FDFCF9" opacity="1.000000" stroke="none" 
	d="
M144.000000,172.000610 
	C138.109543,173.531403 136.626740,177.797989 136.988297,183.240372 
	C137.065140,184.397034 137.134796,185.582123 136.975464,186.721619 
	C136.358322,191.134827 137.443329,194.301254 143.000000,194.408157 
	C143.000000,196.544174 143.000000,198.335464 143.000000,200.563385 
	C140.629440,201.000000 138.258896,201.000000 135.443512,200.608337 
	C134.913986,199.176575 134.829300,198.136475 134.811844,196.797836 
	C134.609650,195.989822 134.340225,195.480347 134.039825,194.593353 
	C133.875168,193.216202 133.741501,192.216568 133.722046,190.867828 
	C133.285858,189.637466 132.735458,188.756195 132.179718,187.512741 
	C132.161163,186.426224 132.147980,185.701889 132.405060,184.687134 
	C132.841568,180.554688 133.007782,176.712662 133.491837,172.803925 
	C134.170288,171.989731 134.530914,171.242264 135.271713,170.711288 
	C136.218750,170.969620 136.785599,171.011475 137.747803,171.036072 
	C140.095444,171.346085 142.047714,171.673355 144.000000,172.000610 
z"/>
<path fill="#FCCDAC" opacity="1.000000" stroke="none" 
	d="
M131.000000,201.000000 
	C127.969406,201.000000 124.938812,201.000000 121.446442,200.553467 
	C120.960907,195.426788 120.937149,190.746658 121.288864,186.050110 
	C124.113968,187.403473 126.561867,188.361160 129.286224,186.281570 
	C130.051086,191.373550 130.525543,196.186783 131.000000,201.000000 
z"/>
<path fill="#FEFAED" opacity="1.000000" stroke="none" 
	d="
M191.035446,152.997864 
	C191.410400,153.766632 192.198853,154.599518 192.091248,155.293457 
	C191.516876,158.997696 190.187012,162.654251 190.097046,166.351120 
	C189.823730,177.582565 189.999695,188.824936 190.000336,200.531525 
	C189.555557,201.000000 189.111099,201.000000 188.344055,200.562836 
	C187.666840,195.754639 187.312210,191.383621 186.972229,186.567749 
	C186.978500,183.748169 186.970169,181.373428 186.979004,178.534210 
	C186.931549,173.686890 186.866959,169.304062 186.835251,164.505646 
	C188.257248,160.392670 189.646347,156.695267 191.035446,152.997864 
z"/>
<path fill="#EFAC91" opacity="1.000000" stroke="none" 
	d="
M19.375000,201.000000 
	C20.004402,186.712433 20.994661,172.423813 22.055540,158.140442 
	C22.107832,157.436417 22.776302,156.778183 23.560616,156.460800 
	C23.979021,162.540192 23.994669,168.257401 24.009598,174.321991 
	C24.336754,175.481796 24.664629,176.294220 24.988022,177.457169 
	C24.985987,178.505493 24.988438,179.203293 24.711391,180.171356 
	C23.621262,187.294403 22.810629,194.147202 21.999998,201.000000 
	C21.250000,201.000000 20.500000,201.000000 19.375000,201.000000 
z"/>
<path fill="#FDEBD8" opacity="1.000000" stroke="none" 
	d="
M91.000000,201.000000 
	C89.285622,201.000000 87.571251,201.000000 85.426575,200.563034 
	C84.329567,195.083755 83.662857,190.041458 83.271233,184.802307 
	C86.326569,179.407806 88.595566,183.720703 91.025101,185.335327 
	C91.021370,186.100021 91.019760,186.537003 91.010437,187.378693 
	C91.001816,192.188950 91.000908,196.594467 91.000000,201.000000 
z"/>
<path fill="#FCDCC8" opacity="1.000000" stroke="none" 
	d="
M82.996140,184.999146 
	C83.662857,190.041458 84.329567,195.083755 84.998138,200.563034 
	C83.933327,201.000000 82.866661,201.000000 81.391052,200.562897 
	C80.317551,196.203568 79.171700,192.289856 79.102173,188.357101 
	C79.000023,182.579361 79.666824,176.787994 80.355347,171.005157 
	C81.800369,171.338593 82.896065,171.669296 83.994888,172.423401 
	C83.664055,176.897568 83.330093,180.948364 82.996140,184.999146 
z"/>
<path fill="#FCDFCA" opacity="1.000000" stroke="none" 
	d="
M174.000000,201.000000 
	C172.933212,201.000000 171.866425,201.000000 170.401367,200.551132 
	C170.337799,195.069992 170.672485,190.037735 171.346909,184.953552 
	C175.668335,181.854309 176.902069,177.808395 176.365082,173.049408 
	C177.349106,174.304489 178.581680,175.538101 178.511749,176.692856 
	C178.222198,181.474472 177.538773,186.232254 177.000000,190.999390 
	C177.000000,191.000000 176.998779,191.000000 176.678207,191.041290 
	C175.906097,191.721497 175.454575,192.360443 174.713593,193.274872 
	C174.282761,196.033569 174.141373,198.516785 174.000000,201.000000 
z"/>
<path fill="#FCFCF0" opacity="1.000000" stroke="none" 
	d="
M1.000000,152.468658 
	C6.847431,154.169937 4.677886,159.451324 5.723656,164.105301 
	C5.931194,165.060333 5.907299,165.484726 5.547427,165.991089 
	C4.847307,167.069504 4.483164,168.065933 4.092072,169.517838 
	C3.043415,176.648865 2.021708,183.324432 1.000000,190.000000 
	C1.000000,177.645767 1.000000,165.291550 1.000000,152.468658 
z"/>
<path fill="#EBC6A0" opacity="1.000000" stroke="none" 
	d="
M1.000000,190.250000 
	C2.021708,183.324432 3.043415,176.648865 4.427524,169.437241 
	C5.154418,167.903824 5.518911,166.906464 5.883404,165.909103 
	C5.907299,165.484726 5.931194,165.060333 5.970526,164.317535 
	C6.627939,164.453476 7.269916,164.907852 7.957203,165.683868 
	C8.280438,172.816986 8.581375,179.627792 8.785082,186.441498 
	C8.798825,186.901184 8.079154,187.382797 7.336185,187.546570 
	C6.607267,186.631897 6.242751,186.024857 5.878239,185.417801 
	C5.284268,186.068146 4.676537,186.706543 4.099340,187.371460 
	C3.056605,188.572617 2.032109,189.789612 1.000002,191.000000 
	C1.000000,191.000000 1.000000,190.500000 1.000000,190.250000 
z"/>
<path fill="#ECD9C0" opacity="1.000000" stroke="none" 
	d="
M83.991760,172.000000 
	C82.896065,171.669296 81.800369,171.338593 80.358444,170.749878 
	C80.017868,170.321793 80.023514,170.151718 80.484138,169.982361 
	C84.301918,169.979080 87.664726,169.975082 91.027527,169.971085 
	C92.354393,175.760376 93.686836,181.548401 95.006294,187.339386 
	C95.982956,191.625854 96.942711,195.916153 97.955002,200.602386 
	C96.933327,201.000000 95.866661,201.000000 94.402824,200.562408 
	C93.993835,199.132889 94.178963,198.089340 93.934929,197.158264 
	C93.039864,193.743271 92.002159,190.365646 91.018158,186.973969 
	C91.019760,186.537003 91.021370,186.100021 91.295097,185.090530 
	C91.098679,181.670761 90.269928,178.827057 90.241806,175.975464 
	C90.193573,171.086121 87.978012,170.331253 83.991760,172.000000 
z"/>
<path fill="#F2BEAC" opacity="1.000000" stroke="none" 
	d="
M80.029160,169.981628 
	C80.023514,170.151718 80.017868,170.321793 80.009117,170.747162 
	C79.666824,176.787994 79.000023,182.579361 79.102173,188.357101 
	C79.171700,192.289856 80.317551,196.203568 80.991058,200.562897 
	C79.611107,201.000000 78.222221,201.000000 76.416672,201.000000 
	C75.985786,198.947220 75.971565,196.894455 76.292122,194.118561 
	C76.801468,184.588699 76.976036,175.781921 77.375404,167.087799 
	C78.386848,167.150116 79.173492,167.099777 79.965469,167.287735 
	C79.970802,167.526016 79.999695,168.001831 79.997345,168.332001 
	C80.006378,169.101990 80.017769,169.541809 80.029160,169.981628 
z"/>
<path fill="#FAE0C4" opacity="1.000000" stroke="none" 
	d="
M186.961823,178.998703 
	C186.970169,181.373428 186.978500,183.748169 186.581787,186.571808 
	C181.367554,188.064758 182.933594,191.268143 183.792542,194.176666 
	C184.469376,196.468521 185.259933,198.726807 186.000000,201.000000 
	C184.933334,201.000000 183.866653,201.000000 182.398529,200.661957 
	C181.666718,199.548569 181.336365,198.773209 181.005432,197.554153 
	C180.999146,194.742538 180.993469,192.374619 180.992126,189.585144 
	C181.685638,183.383438 182.318771,177.595322 183.137848,171.833633 
	C183.235779,171.144791 184.345428,170.599762 184.990143,170.419128 
	C184.946075,172.914124 184.712418,174.999710 184.949203,177.030441 
	C185.033676,177.754898 186.257858,178.346481 186.961823,178.998703 
z"/>
<path fill="#F0AD8A" opacity="1.000000" stroke="none" 
	d="
M120.913391,186.066528 
	C120.937149,190.746658 120.960907,195.426788 120.992332,200.553467 
	C118.958336,201.000000 116.916664,201.000000 114.437500,200.545563 
	C114.939972,195.478226 115.879944,190.865341 117.227768,186.175964 
	C118.728210,186.088486 119.820801,186.077499 120.913391,186.066528 
z"/>
<path fill="#EBAC86" opacity="1.000000" stroke="none" 
	d="
M6.997394,133.999176 
	C6.371728,131.656235 5.746063,129.313293 5.120397,126.970352 
	C4.754626,126.990021 4.388855,127.009689 4.023084,127.029358 
	C3.223417,129.220276 2.423750,131.411194 1.312042,133.801056 
	C1.000000,131.958328 1.000000,129.916672 1.312153,127.258667 
	C2.416884,124.623764 3.048966,122.520264 4.056881,120.615646 
	C4.761757,119.283669 5.980470,118.223625 7.150883,117.401024 
	C7.528562,119.507202 7.984407,121.272606 7.863925,122.997757 
	C7.655963,125.975464 8.873596,127.581230 11.777311,128.418182 
	C11.161304,130.526947 10.511538,132.280777 9.503751,134.029022 
	C8.429619,134.015335 7.713506,134.007248 6.997394,133.999176 
z"/>
<path fill="#EF763D" opacity="1.000000" stroke="none" 
	d="
M50.428463,201.000000 
	C49.031162,195.206909 48.144276,189.398041 47.033699,183.632263 
	C46.768436,182.255081 45.757130,181.021576 45.279449,179.187256 
	C44.979279,178.103470 44.488617,177.554428 43.997955,177.005402 
	C43.997955,177.005402 43.999847,177.000000 44.001724,176.625427 
	C44.660069,175.156174 45.316532,174.061493 46.129257,173.311066 
	C47.292149,177.265732 48.077282,180.957611 49.366840,184.463943 
	C50.693268,188.070541 52.511810,191.496155 54.295959,195.310669 
	C54.984612,197.413589 55.492306,199.206787 56.000000,201.000000 
	C54.285641,201.000000 52.571285,201.000000 50.428463,201.000000 
z"/>
<path fill="#FAF2DE" opacity="1.000000" stroke="none" 
	d="
M56.375000,201.000000 
	C55.492306,199.206787 54.984612,197.413589 54.349289,194.878723 
	C52.800934,188.427795 51.380203,182.718521 49.959473,177.009232 
	C51.406059,179.222015 53.345734,181.261124 54.198711,183.682968 
	C56.095215,189.067749 57.447651,194.644150 59.010933,200.571228 
	C58.250000,201.000000 57.500000,201.000000 56.375000,201.000000 
z"/>
<path fill="#E8C1B2" opacity="1.000000" stroke="none" 
	d="
M164.132675,196.972656 
	C164.165955,195.929276 164.199249,194.885895 164.511108,193.193481 
	C165.859283,186.027130 166.928909,179.509827 168.250153,172.782257 
	C168.661835,171.714752 168.821915,170.857483 169.233337,170.168762 
	C169.658295,170.902573 169.831894,171.467850 170.006653,172.467041 
	C170.006439,174.932541 170.005051,176.964127 169.715729,179.267120 
	C168.618530,186.692352 167.809265,193.846176 167.000000,201.000000 
	C166.555557,201.000000 166.111099,201.000000 165.298401,200.641266 
	C164.664337,199.179245 164.398514,198.075943 164.132675,196.972656 
z"/>
<path fill="#FEE8D5" opacity="1.000000" stroke="none" 
	d="
M180.987793,190.006714 
	C180.993469,192.374619 180.999146,194.742538 180.741364,197.758957 
	C180.318604,199.271652 180.159302,200.135818 180.000000,201.000000 
	C178.285645,201.000000 176.571289,201.000000 174.428467,201.000000 
	C174.141373,198.516785 174.282761,196.033569 175.033966,193.233521 
	C176.095444,192.277802 176.547104,191.638901 176.998779,191.000000 
	C176.998779,191.000000 177.000000,191.000000 177.320053,190.861130 
	C178.755997,190.483749 179.871902,190.245224 180.987793,190.006714 
z"/>
<path fill="#B08061" opacity="1.000000" stroke="none" 
	d="
M116.819908,186.252457 
	C115.879944,190.865341 114.939972,195.478226 114.000000,200.545563 
	C112.933327,201.000000 111.866661,201.000000 110.399994,201.000000 
	C110.399223,195.730194 110.798447,190.460373 111.878464,185.048157 
	C113.979469,185.354630 115.399689,185.803543 116.819908,186.252457 
z"/>
<path fill="#D7632F" opacity="1.000000" stroke="none" 
	d="
M38.000000,201.000000 
	C37.555664,201.000000 37.111328,201.000000 36.337326,200.632812 
	C35.680733,199.164017 35.353806,198.062378 35.028191,196.536179 
	C34.485458,190.686737 33.941418,185.261887 33.757507,179.904297 
	C34.742996,181.277756 35.454861,182.550476 35.975479,183.897156 
	C37.019375,186.597412 37.958748,189.338089 38.949284,192.427475 
	C38.954693,193.525375 38.952084,194.257874 38.674622,195.259521 
	C38.266514,197.352432 38.133255,199.176224 38.000000,201.000000 
z"/>
<path fill="#ECB890" opacity="1.000000" stroke="none" 
	d="
M1.000002,191.486572 
	C2.032109,189.789612 3.056605,188.572617 4.099340,187.371460 
	C4.676537,186.706543 5.284268,186.068146 5.878239,185.417801 
	C6.242751,186.024857 6.607267,186.631897 6.979036,187.592834 
	C6.326261,192.007812 5.666232,196.068924 5.003102,200.565018 
	C3.713397,201.000000 2.426794,201.000000 1.000000,201.000000 
	C1.000000,197.991776 1.000000,194.982468 1.000002,191.486572 
z"/>
<path fill="#F8C4B4" opacity="1.000000" stroke="none" 
	d="
M167.375000,201.000000 
	C167.809265,193.846176 168.618530,186.692352 169.970337,179.269363 
	C170.682648,179.001999 170.852417,179.003784 171.017883,179.433853 
	C171.011444,181.576584 171.009308,183.291031 171.007172,185.005463 
	C170.672485,190.037735 170.337799,195.069992 170.001556,200.551132 
	C169.250000,201.000000 168.500000,201.000000 167.375000,201.000000 
z"/>
<path fill="#FFFEF5" opacity="1.000000" stroke="none" 
	d="
M19.945469,45.615765 
	C13.977357,46.005680 7.956949,46.001640 1.468270,45.998802 
	C1.000000,45.250000 1.000000,44.500000 1.000000,43.375000 
	C5.819710,43.000324 10.649417,42.826748 15.453267,43.102928 
	C16.968832,43.190060 18.415157,44.481525 19.945469,45.615765 
z"/>
<path fill="#FEFAED" opacity="1.000000" stroke="none" 
	d="
M201.000000,148.535355 
	C198.608582,149.370270 196.217178,149.740555 193.539200,149.805878 
	C193.529968,149.029358 193.807312,148.557831 194.384567,147.912048 
	C198.638336,145.231979 200.120117,141.947327 198.083206,137.427582 
	C197.771179,136.735199 197.972168,135.811615 198.318909,134.996521 
	C199.467957,134.998703 200.233978,134.999344 201.000000,135.000000 
	C201.000000,139.356903 201.000000,143.713806 201.000000,148.535355 
z"/>
<path fill="#DAAE8C" opacity="1.000000" stroke="none" 
	d="
M131.399994,201.000000 
	C130.525543,196.186783 130.051086,191.373550 129.385345,186.015717 
	C129.463806,184.974350 129.733551,184.477631 130.280121,184.085312 
	C131.082901,184.452347 131.608841,184.714951 132.134796,184.977554 
	C132.147980,185.701889 132.161163,186.426224 132.105042,187.861389 
	C132.559738,189.453796 133.083786,190.335358 133.607819,191.216919 
	C133.741501,192.216568 133.875168,193.216202 134.012833,194.887573 
	C134.259430,196.071655 134.502014,196.584000 134.744614,197.096359 
	C134.829300,198.136475 134.913986,199.176575 134.999329,200.608337 
	C133.933334,201.000000 132.866653,201.000000 131.399994,201.000000 
z"/>
<path fill="#D09474" opacity="1.000000" stroke="none" 
	d="
M22.374998,201.000000 
	C22.810629,194.147202 23.621262,187.294403 24.714674,180.618683 
	C25.087692,182.278427 25.177931,183.761093 25.268169,185.243774 
	C25.210529,190.186386 25.152887,195.128998 25.047623,200.535797 
	C24.250000,201.000000 23.500000,201.000000 22.374998,201.000000 
z"/>
<path fill="#F3D8BC" opacity="1.000000" stroke="none" 
	d="
M186.333313,201.000000 
	C185.259933,198.726807 184.469376,196.468521 183.792542,194.176666 
	C182.933594,191.268143 181.367554,188.064758 186.567169,187.016663 
	C187.312210,191.383621 187.666840,195.754639 188.010727,200.562836 
	C187.555557,201.000000 187.111099,201.000000 186.333313,201.000000 
z"/>
<path fill="#F7DD54" opacity="1.000000" stroke="none" 
	d="
M6.969687,117.042130 
	C5.980470,118.223625 4.761757,119.283669 4.056881,120.615646 
	C3.048966,122.520264 2.416884,124.623764 1.312153,126.821167 
	C1.000000,123.307091 1.000000,119.614182 1.000000,115.460632 
	C2.850579,114.828636 4.701158,114.657280 6.776660,115.096130 
	C6.990952,116.151611 6.980320,116.596870 6.969687,117.042130 
z"/>
<path fill="#FCE7CE" opacity="1.000000" stroke="none" 
	d="
M91.010437,187.378693 
	C92.002159,190.365646 93.039864,193.743271 93.934929,197.158264 
	C94.178963,198.089340 93.993835,199.132889 94.002831,200.562408 
	C93.250000,201.000000 92.500000,201.000000 91.375000,201.000000 
	C91.000908,196.594467 91.001816,192.188950 91.010437,187.378693 
z"/>
<path fill="#FEFAED" opacity="1.000000" stroke="none" 
	d="
M201.000000,125.000000 
	C201.000000,127.042320 201.000000,129.084641 200.606506,131.563934 
	C199.164963,131.999603 198.116928,131.998291 197.040863,131.633041 
	C196.373779,129.514206 195.734695,127.759293 195.468124,125.956299 
	C197.560425,125.605476 199.280212,125.302742 201.000000,125.000000 
z"/>
<path fill="#FCF9EA" opacity="1.000000" stroke="none" 
	d="
M201.000000,124.625000 
	C199.280212,125.302742 197.560425,125.605476 195.212540,125.936821 
	C194.414322,125.961441 194.244186,125.957443 194.061096,125.747681 
	C194.048141,125.541916 193.979111,125.135391 194.110794,124.849731 
	C194.855927,123.733704 195.469360,122.903328 196.082794,122.072952 
	C196.082794,122.072945 195.988754,122.031990 196.440170,122.055969 
	C198.261063,122.053299 199.630524,122.026649 201.000000,122.000000 
	C201.000000,122.750000 201.000000,123.500000 201.000000,124.625000 
z"/>
<path fill="#EF763D" opacity="1.000000" stroke="none" 
	d="
M38.333328,201.000000 
	C38.133255,199.176224 38.266514,197.352432 39.012817,195.264526 
	C40.075138,195.006577 40.524406,195.012756 40.973675,195.018951 
	C40.982399,196.080643 40.991123,197.142349 40.754158,198.768600 
	C40.338982,199.888763 40.169491,200.444382 40.000000,201.000000 
	C39.555553,201.000000 39.111111,201.000000 38.333328,201.000000 
z"/>
<path fill="#FDFCF9" opacity="1.000000" stroke="none" 
	d="
M163.762939,197.013672 
	C164.398514,198.075943 164.664337,199.179245 164.965088,200.641266 
	C163.933334,201.000000 162.866653,201.000000 161.399994,201.000000 
	C161.797745,199.684891 162.595490,198.369781 163.762939,197.013672 
z"/>
<path fill="#C4F040" opacity="1.000000" stroke="none" 
	d="
M201.000000,121.625000 
	C199.630524,122.026649 198.261063,122.053299 196.481720,122.086777 
	C197.459183,121.074066 198.846512,120.054520 200.616913,119.017487 
	C201.000000,119.750000 201.000000,120.500000 201.000000,121.625000 
z"/>
<path fill="#FCF9EA" opacity="1.000000" stroke="none" 
	d="
M197.068878,131.996979 
	C198.116928,131.998291 199.164963,131.999603 200.606506,132.000458 
	C201.000000,132.750000 201.000000,133.500000 201.000000,134.625000 
	C200.233978,134.999344 199.467957,134.998703 198.201630,134.702667 
	C197.490494,133.603851 197.279678,132.800415 197.068878,131.996979 
z"/>
<path fill="#B45123" opacity="1.000000" stroke="none" 
	d="
M35.026878,196.960754 
	C35.353806,198.062378 35.680733,199.164017 36.003830,200.632812 
	C35.555553,201.000000 35.111111,201.000000 34.333328,201.000000 
	C33.808262,200.404449 33.616524,199.808899 33.285423,198.665863 
	C33.773003,197.732513 34.399940,197.346634 35.026878,196.960754 
z"/>
<path fill="#FDE7CE" opacity="1.000000" stroke="none" 
	d="
M180.333344,201.000000 
	C180.159302,200.135818 180.318604,199.271652 180.741959,198.202667 
	C181.336365,198.773209 181.666718,199.548569 181.998535,200.661957 
	C181.555557,201.000000 181.111099,201.000000 180.333344,201.000000 
z"/>
<path fill="#FB8347" opacity="1.000000" stroke="none" 
	d="
M40.333332,201.000000 
	C40.169491,200.444382 40.338982,199.888763 40.754391,199.166748 
	C41.320267,199.468216 41.640224,199.936066 41.980091,200.701965 
	C41.555553,201.000000 41.111111,201.000000 40.333332,201.000000 
z"/>
<path fill="#FDFDF9" opacity="1.000000" stroke="none" 
	d="
M110.581055,66.015244 
	C110.702583,66.774994 110.373291,67.526192 109.746979,68.666031 
	C109.085670,69.099159 108.721397,69.143631 107.968750,69.135605 
	C106.370728,69.414810 105.161072,69.746513 103.642578,69.899422 
	C100.763565,68.883301 98.193413,68.045967 95.332794,67.196167 
	C94.686958,67.033981 94.331589,66.884262 93.802269,66.533997 
	C93.285278,66.296692 92.942238,66.259933 92.319359,66.227333 
	C91.695045,66.094887 91.350555,65.958290 90.936600,65.486206 
	C89.559349,65.186150 88.251572,65.221581 86.553185,65.309021 
	C85.114540,65.311684 84.066513,65.262352 83.018478,65.213020 
	C81.932144,64.818802 80.845802,64.424583 79.381744,64.027359 
	C80.566353,59.935135 84.110344,59.341049 87.852600,58.426239 
	C92.764587,57.225479 94.245895,59.977982 96.667053,63.331669 
	C98.055275,65.254562 102.211319,65.298294 105.178108,65.914490 
	C106.766670,66.244423 108.475601,65.994789 110.581055,66.015244 
z"/>
<path fill="#FEFEF6" opacity="1.000000" stroke="none" 
	d="
M43.206242,49.807743 
	C39.225842,48.701210 35.531776,47.368046 31.424591,46.032906 
	C30.241795,44.674789 29.472122,43.318645 28.601006,41.783760 
	C28.789511,41.645054 29.230190,41.093914 29.481474,41.169811 
	C33.459755,42.371449 37.535000,43.383835 41.301285,45.077316 
	C42.470383,45.602985 42.786144,48.026478 43.206242,49.807743 
z"/>
<path fill="#FAF2DE" opacity="1.000000" stroke="none" 
	d="
M59.110439,112.893349 
	C54.437031,110.416451 54.679474,105.642700 54.109520,101.463913 
	C53.627205,97.927650 54.010113,94.273392 54.010113,90.671349 
	C53.495712,90.459900 52.981316,90.248444 52.466915,90.036987 
	C51.671780,91.479393 50.133972,92.959671 50.205292,94.357872 
	C50.590446,101.908630 51.313683,109.445198 52.074677,116.971046 
	C52.218334,118.391739 52.651951,120.335747 53.650021,121.020424 
	C57.679588,123.784676 56.059872,128.146698 56.582890,131.657654 
	C56.803177,133.136414 53.446335,135.148056 51.140461,136.748047 
	C50.424053,136.441650 50.282757,136.304703 50.298409,135.884140 
	C51.663902,133.787109 52.872448,131.973709 54.346802,129.978043 
	C54.412643,128.865326 54.212677,127.934883 54.008183,127.000832 
	C54.003658,126.997231 54.000378,126.986008 53.955978,126.652504 
	C52.637512,125.191788 51.363438,124.064575 50.069530,122.519463 
	C49.380173,114.882614 48.465069,107.674896 48.134521,100.440460 
	C47.940594,96.196114 48.172092,91.791214 49.134262,87.674004 
	C50.139610,83.372002 53.650894,82.497925 57.211834,85.339211 
	C54.937283,95.011520 56.096733,104.021446 59.110439,112.893349 
z"/>
<path fill="#CC442D" opacity="1.000000" stroke="none" 
	d="
M59.092255,112.908340 
	C56.096733,104.021446 54.937283,95.011520 57.490623,85.355423 
	C58.264721,84.959137 58.638584,84.962456 59.015854,85.304436 
	C59.024010,86.094635 59.028767,86.546173 58.967094,87.411324 
	C54.425152,100.741959 64.126167,123.551727 73.935913,123.566391 
	C74.301270,120.385818 74.661301,117.251564 75.060822,114.548004 
	C75.240585,115.536865 75.380867,116.095039 75.540451,116.940369 
	C75.731323,118.636276 75.902901,120.045013 76.073593,121.819687 
	C76.409630,122.780556 76.746536,123.375504 77.081261,123.911758 
	C77.079071,123.853065 77.195541,123.837845 77.096207,124.187790 
	C76.636093,125.422386 76.275299,126.307045 75.580292,127.086823 
	C74.137367,126.699821 73.028671,126.417694 71.614670,125.987106 
	C69.244041,124.517166 67.178711,123.195702 65.074966,121.586853 
	C64.670731,120.896133 64.304909,120.492805 63.939075,120.089478 
	C63.929661,119.737656 63.793839,119.444389 63.329929,118.741287 
	C62.711903,117.895645 62.295551,117.518372 61.879200,117.141098 
	C61.879200,117.141098 61.980343,117.041351 61.910076,116.805031 
	C61.551926,116.391159 61.264038,116.213615 60.790905,115.970177 
	C60.605656,115.904289 60.213287,115.930283 60.213287,115.930283 
	C60.125240,115.490974 60.037197,115.051674 59.876564,114.055023 
	C59.702305,113.130798 59.462204,112.926933 59.083668,112.886078 
	C59.083668,112.886078 59.074074,112.923325 59.092255,112.908340 
z"/>
<path fill="#FAF2DE" opacity="1.000000" stroke="none" 
	d="
M50.777336,136.783997 
	C49.700726,139.659897 48.136631,142.235596 47.178528,145.019821 
	C44.892185,151.663910 48.303543,157.910889 48.894371,164.365814 
	C49.112179,166.745438 49.305363,169.127304 48.959545,171.482117 
	C47.648441,168.511276 46.887569,165.566498 45.970909,162.329254 
	C45.176266,160.349716 44.537411,158.662598 43.953136,156.569672 
	C41.408913,148.074142 44.543678,141.743195 50.141457,136.167770 
	C50.282757,136.304703 50.424053,136.441650 50.777336,136.783997 
z"/>
<path fill="#D4AB9D" opacity="1.000000" stroke="none" 
	d="
M77.150604,166.975159 
	C76.976036,175.781921 76.801468,184.588699 76.325195,193.679565 
	C75.670265,192.386795 74.986084,190.803207 75.017982,189.234177 
	C75.212898,179.645691 75.785378,170.060150 75.768829,160.474411 
	C75.762108,156.580185 74.591827,152.687988 74.286728,148.423187 
	C75.745644,149.386246 76.868820,150.720993 78.002838,152.267914 
	C78.013680,152.480118 78.032173,152.904648 77.782333,153.095139 
	C77.666504,154.190689 77.800499,155.095734 77.944839,156.426163 
	C77.686981,160.226074 77.418793,163.600616 77.150604,166.975159 
z"/>
<path fill="#C87750" opacity="1.000000" stroke="none" 
	d="
M46.126694,162.621704 
	C46.887569,165.566498 47.648441,168.511276 48.655624,171.735199 
	C48.936619,172.464355 48.971310,172.914368 49.072189,173.974274 
	C49.329063,174.905563 49.519760,175.226990 49.710457,175.548401 
	C49.713295,175.884033 49.716129,176.219666 49.839218,176.782257 
	C51.380203,182.718521 52.800934,188.427795 54.168335,194.569031 
	C52.511810,191.496155 50.693268,188.070541 49.366840,184.463943 
	C48.077282,180.957611 47.292149,177.265732 46.131142,172.992065 
	C45.955616,171.903198 45.934471,171.477615 45.920364,170.745590 
	C45.613266,169.952515 45.299133,169.465866 44.984863,168.655212 
	C44.974487,167.899307 44.964249,167.467438 45.008026,166.660034 
	C45.416927,165.063583 45.771812,163.842636 46.126694,162.621704 
z"/>
<path fill="#EFB4A4" opacity="1.000000" stroke="none" 
	d="
M65.113373,121.874237 
	C67.178711,123.195702 69.244041,124.517166 71.702103,126.310730 
	C72.857933,127.917984 73.621017,129.053146 74.389923,130.494293 
	C74.511177,131.190536 74.626595,131.580795 74.370712,132.093811 
	C73.593300,132.862442 73.187187,133.508316 72.781067,134.154175 
	C70.259155,130.979675 67.737244,127.805191 65.055908,123.974937 
	C64.968788,122.837532 65.041084,122.355881 65.113373,121.874237 
z"/>
<path fill="#C47161" opacity="1.000000" stroke="none" 
	d="
M72.834549,134.532532 
	C73.187187,133.508316 73.593300,132.862442 74.640518,132.176056 
	C76.474800,133.393402 77.667976,134.651306 78.924591,136.313873 
	C78.979836,137.808945 78.971642,138.899338 78.708862,140.215057 
	C78.191246,141.671646 77.928223,142.902939 77.602394,144.428223 
	C76.008125,142.133636 74.558945,140.047867 73.109772,137.962097 
	C73.035858,136.945023 72.961952,135.927963 72.834549,134.532532 
z"/>
<path fill="#DC9A89" opacity="1.000000" stroke="none" 
	d="
M73.041763,138.388641 
	C74.558945,140.047867 76.008125,142.133636 77.602394,144.428223 
	C77.928223,142.902939 78.191246,141.671646 78.992752,140.446259 
	C79.728737,141.662033 79.926247,142.871933 80.106171,144.493042 
	C80.090034,146.279968 80.091476,147.655655 79.729477,148.995300 
	C77.535736,148.005142 75.705444,147.051056 73.875145,146.096970 
	C73.574684,143.669708 73.274216,141.242447 73.041763,138.388641 
z"/>
<path fill="#F2BEAC" opacity="1.000000" stroke="none" 
	d="
M73.874748,146.411346 
	C75.705444,147.051056 77.535736,148.005142 80.004089,149.279572 
	C80.757835,152.395584 80.873528,155.191269 80.711807,157.861267 
	C79.601105,157.157318 78.767807,156.579056 77.934502,156.000793 
	C77.800499,155.095734 77.666504,154.190689 77.943878,153.027313 
	C78.234169,152.531235 78.113075,152.293488 77.991989,152.055725 
	C76.868820,150.720993 75.745644,149.386246 74.287201,148.016342 
	C73.926071,147.562683 73.900208,147.144211 73.874748,146.411346 
z"/>
<path fill="#FAF2DE" opacity="1.000000" stroke="none" 
	d="
M65.074966,121.586853 
	C65.041084,122.355881 64.968788,122.837532 64.863060,123.685257 
	C64.438316,122.928925 64.046997,121.806511 63.797379,120.386795 
	C64.304909,120.492805 64.670731,120.896133 65.074966,121.586853 
z"/>
<path fill="#FAF2DE" opacity="1.000000" stroke="none" 
	d="
M59.899780,115.971786 
	C60.213287,115.930283 60.605656,115.904289 60.791771,116.256378 
	C60.979637,116.799271 60.981392,116.990089 60.983143,117.180916 
	C60.153690,117.216660 59.324238,117.252403 58.494785,117.288155 
	C58.858616,116.863205 59.222446,116.438248 59.899780,115.971786 
z"/>
<path fill="#EFB4A4" opacity="1.000000" stroke="none" 
	d="
M61.890129,117.441788 
	C62.295551,117.518372 62.711903,117.895645 63.149410,118.571129 
	C62.747395,118.493721 62.324226,118.118103 61.890129,117.441788 
z"/>
<path fill="#FAF2DE" opacity="1.000000" stroke="none" 
	d="
M59.098160,113.111160 
	C59.462204,112.926933 59.702305,113.130798 59.754162,113.742050 
	C59.410511,113.857620 59.213276,113.640892 59.098160,113.111160 
z"/>
<path fill="#FAF2DE" opacity="1.000000" stroke="none" 
	d="
M49.750900,175.260071 
	C49.519760,175.226990 49.329063,174.905563 49.172066,174.298843 
	C49.400955,174.332932 49.596149,174.652328 49.750900,175.260071 
z"/>
<path fill="#EFB4A4" opacity="1.000000" stroke="none" 
	d="
M61.203297,117.181778 
	C60.981392,116.990089 60.979637,116.799271 60.977020,116.322266 
	C61.264038,116.213615 61.551926,116.391159 61.849106,116.841431 
	C61.858402,117.114151 61.423450,117.182632 61.203297,117.181778 
z"/>
<path fill="#C68A7B" opacity="1.000000" stroke="none" 
	d="
M22.861404,137.079498 
	C23.165298,138.142090 23.469189,139.204697 23.402489,140.561676 
	C21.664186,140.546158 20.296473,140.236252 18.978611,139.570282 
	C19.138844,137.432724 21.159801,134.830460 16.954082,134.498581 
	C16.218390,131.975998 15.670427,129.777283 15.177679,127.337616 
	C15.232891,127.096680 15.265558,126.603378 15.265558,126.603378 
	C15.265558,126.603378 15.588346,126.323166 16.064905,126.262085 
	C17.519796,126.114471 18.498127,126.027939 19.612019,126.332306 
	C20.785522,130.175308 21.823463,133.627396 22.861404,137.079498 
z"/>
<path fill="#CB8C21" opacity="1.000000" stroke="none" 
	d="
M14.959263,126.366348 
	C15.265558,126.603378 15.232891,127.096680 14.769756,127.468994 
	C13.452266,127.915298 12.597908,127.989281 11.743551,128.063263 
	C8.873596,127.581230 7.655963,125.975464 7.863925,122.997757 
	C7.984407,121.272606 7.528562,119.507202 7.150884,117.401024 
	C6.980320,116.596870 6.990952,116.151611 6.997777,115.373306 
	C11.018746,115.724136 11.137836,118.838692 10.949003,121.841537 
	C10.769369,124.698120 11.652535,126.220596 14.959263,126.366348 
z"/>
<path fill="#A1772A" opacity="1.000000" stroke="none" 
	d="
M19.476460,125.941406 
	C18.498127,126.027939 17.519796,126.114471 16.172909,126.250916 
	C15.905477,122.139244 16.006603,117.977661 16.193947,113.345146 
	C17.209347,114.628220 18.138531,116.382210 19.261969,118.816795 
	C19.462969,121.645393 19.469713,123.793396 19.476460,125.941406 
z"/>
<path fill="#ECFDB2" opacity="1.000000" stroke="none" 
	d="
M197.477203,97.470016 
	C196.377167,101.673103 196.130722,106.280785 193.760666,109.108650 
	C188.649353,115.207291 178.379822,112.972626 176.271896,105.297737 
	C174.932114,100.419655 175.058060,94.907906 175.636719,89.806198 
	C176.110535,85.628845 179.408554,82.883453 183.828156,82.262466 
	C187.720993,81.715492 193.709351,85.276924 195.435486,89.414818 
	C196.437393,91.816566 196.746948,94.507141 197.477203,97.470016 
M180.004761,87.483360 
	C179.328140,92.320618 178.065826,97.167152 178.142334,101.992470 
	C178.216385,106.662979 186.100540,110.644920 189.843277,108.503273 
	C194.230026,105.993118 196.154175,97.874893 193.665192,92.378120 
	C190.740082,85.918190 187.481689,84.550873 180.004761,87.483360 
z"/>
<path fill="#B8E038" opacity="1.000000" stroke="none" 
	d="
M166.959503,121.066879 
	C166.973053,120.602303 166.986588,120.137726 167.006073,119.324051 
	C168.348969,119.828110 169.827835,120.521698 170.997894,121.562469 
	C175.676254,125.723831 179.237122,122.144440 183.617157,120.034355 
	C184.468552,120.583984 184.724686,121.071922 184.631271,121.702301 
	C183.472595,123.206642 182.663467,124.568542 181.516220,125.979614 
	C177.999420,126.266319 174.820724,126.503845 171.449432,126.459366 
	C170.867157,125.616829 170.477478,125.056305 170.047348,124.255203 
	C169.672028,123.686302 169.337143,123.357986 168.796509,122.834473 
	C168.046997,122.115143 167.503250,121.591011 166.959503,121.066879 
z"/>
<path fill="#C4F040" opacity="1.000000" stroke="none" 
	d="
M184.980804,121.559860 
	C184.724686,121.071922 184.468552,120.583984 183.953354,119.962296 
	C185.853271,116.369095 188.077255,118.154770 189.496582,120.213554 
	C191.151199,122.613686 192.966934,122.812195 195.714294,122.048004 
	C195.469360,122.903328 194.855927,123.733704 193.766586,124.830612 
	C190.885788,124.364540 188.480896,123.631935 185.931900,122.649414 
	C185.518814,122.119629 185.249802,121.839745 184.980804,121.559860 
z"/>
<path fill="#B8E038" opacity="1.000000" stroke="none" 
	d="
M163.873199,113.808960 
	C163.632965,113.933540 163.381058,113.736671 163.114197,113.220703 
	C163.354294,113.094116 163.608383,113.289413 163.873199,113.808960 
z"/>
<path fill="#8C8289" opacity="1.000000" stroke="none" 
	d="
M110.485725,133.005646 
	C104.797020,131.724792 104.694618,128.027252 106.287979,122.858871 
	C106.162369,121.651062 106.143799,121.134178 106.163750,120.260925 
	C106.479141,117.943626 106.756020,115.982712 107.223724,113.896408 
	C107.656677,113.561150 107.795876,113.297028 108.158218,112.811386 
	C108.744278,111.479347 109.004272,110.314575 109.462128,108.915634 
	C109.074181,107.724167 108.488373,106.766884 107.926010,105.432480 
	C107.905220,103.812523 107.860985,102.569687 108.149551,101.130371 
	C109.112206,98.374527 110.496994,95.726921 110.210365,93.274673 
	C109.661201,88.576157 108.162453,83.988632 107.435425,79.287804 
	C108.053848,79.133171 108.294556,79.045784 108.830887,79.124832 
	C110.375435,85.376350 111.922318,91.423218 112.710297,97.567413 
	C112.982971,99.693542 110.109131,102.678215 110.854996,104.203537 
	C113.116463,108.828377 110.948029,112.552490 109.867195,116.630508 
	C109.325310,118.675049 109.107353,120.845215 109.079582,122.962975 
	C109.070793,123.632851 110.198448,124.903313 110.724670,124.862022 
	C111.589081,124.794197 112.639503,124.047096 113.147926,123.279396 
	C113.756630,122.360283 113.878502,121.118744 114.525925,120.048477 
	C116.499382,120.781693 118.676361,121.175461 116.717850,123.966843 
	C115.221306,126.442093 114.171074,128.830673 112.864563,131.069702 
	C112.382248,131.896271 111.296333,132.370636 110.485725,133.005646 
z"/>
<path fill="#F4CCAD" opacity="1.000000" stroke="none" 
	d="
M130.003296,183.980896 
	C129.733551,184.477631 129.463806,184.974350 129.094940,185.736938 
	C126.561867,188.361160 124.113968,187.403473 121.288864,186.050095 
	C119.820801,186.077499 118.728210,186.088486 117.227768,186.175964 
	C115.399689,185.803543 113.979469,185.354630 112.206177,184.761871 
	C111.853111,184.617996 111.841499,184.369965 112.299194,184.191833 
	C118.121353,183.458405 119.378174,180.359131 117.104530,174.173431 
	C121.677528,174.714798 125.871254,175.310562 130.855789,176.018677 
	C130.676819,177.690231 130.340057,180.835571 130.003296,183.980896 
z"/>
<path fill="#F3CFD9" opacity="1.000000" stroke="none" 
	d="
M112.817352,164.836578 
	C112.884216,164.117523 112.951073,163.398468 113.358162,162.471786 
	C115.816315,161.847595 117.934235,161.431030 120.421036,161.006042 
	C123.294823,160.040207 125.799744,159.082779 128.997864,157.860413 
	C128.997864,157.978714 128.997864,156.996262 129.312531,156.002655 
	C130.045074,155.971085 130.462936,155.950668 130.925964,156.253876 
	C131.302979,157.377762 131.634842,158.178024 131.705872,159.202545 
	C130.827423,161.102020 129.888443,162.743423 129.707428,164.464554 
	C129.590805,165.573486 130.586868,166.799438 131.305084,168.033508 
	C131.848099,168.183350 132.060226,168.387131 131.982452,169.010590 
	C128.700150,169.690491 125.599152,170.080032 122.489563,170.380829 
	C122.375877,170.391830 122.178032,169.532700 122.306808,169.034119 
	C122.928276,168.724991 123.262360,168.460831 123.596443,168.196686 
	C122.662430,168.090591 121.728409,167.984512 120.755951,167.537003 
	C120.600830,161.989716 117.976257,165.137589 115.920120,165.544678 
	C114.917419,165.274277 114.090057,165.267197 113.152100,165.153442 
	C113.041504,165.046783 112.817352,164.836578 112.817352,164.836578 
z"/>
<path fill="#FEF0DC" opacity="1.000000" stroke="none" 
	d="
M88.994476,150.004883 
	C88.997803,150.442749 89.001122,150.880600 88.736557,151.887146 
	C87.647156,153.970749 86.825623,155.485687 85.958099,156.618591 
	C84.888107,152.117874 83.864098,147.999146 82.870621,143.464737 
	C82.575661,138.024872 82.250183,133.000687 82.175415,127.666138 
	C83.454712,127.060730 84.483292,126.765701 85.661957,126.609612 
	C85.812050,126.748543 85.987732,127.117935 86.010513,127.427643 
	C86.361038,128.494186 86.688789,129.251038 87.011002,130.473602 
	C86.800140,135.736237 86.366684,140.540375 86.518448,145.325958 
	C86.568687,146.910172 88.126144,148.446609 88.994476,150.004883 
z"/>
<path fill="#ECCEC6" opacity="1.000000" stroke="none" 
	d="
M122.019409,169.079071 
	C122.178032,169.532700 122.375877,170.391830 122.489563,170.380829 
	C125.599152,170.080032 128.700150,169.690491 132.226746,169.289139 
	C132.825287,170.465240 132.999649,171.667938 133.174011,172.870651 
	C133.007782,176.712662 132.841568,180.554688 132.405060,184.687134 
	C131.608841,184.714951 131.082901,184.452347 130.280121,184.085312 
	C130.340057,180.835571 130.676819,177.690231 130.855789,176.018677 
	C125.871254,175.310562 121.677528,174.714798 116.795059,174.102600 
	C115.105789,173.764175 114.105270,173.442184 113.104759,173.120209 
	C113.104759,173.120193 112.963341,172.641556 112.971634,172.324829 
	C112.979935,172.008118 113.148064,171.856140 113.446968,171.870514 
	C114.195671,171.317245 114.645470,170.749573 115.293686,170.021271 
	C115.692619,169.585907 115.893127,169.311203 116.345787,169.030670 
	C116.932755,169.052551 117.267570,169.080261 117.738914,169.387329 
	C118.580269,169.444168 119.285095,169.221649 120.245468,169.002075 
	C120.671272,169.003403 120.841537,169.001770 121.264107,169.014801 
	C121.684074,169.046005 121.851738,169.062546 122.019409,169.079071 
z"/>
<path fill="#BA7C5B" opacity="1.000000" stroke="none" 
	d="
M113.716713,155.159409 
	C110.608757,151.736572 110.120811,148.177979 112.692719,144.120285 
	C113.679436,142.563538 113.930862,140.540756 114.821548,138.118484 
	C115.492020,137.353226 115.858284,137.199799 116.515625,137.248459 
	C117.786140,140.054932 118.765579,142.659302 119.737831,145.521179 
	C119.717422,146.121918 119.704208,146.465164 119.486015,147.062378 
	C119.244911,148.173187 119.208786,149.030029 119.125008,150.272034 
	C118.748665,153.894150 116.707146,155.091705 113.716713,155.159409 
z"/>
<path fill="#F7E9D4" opacity="1.000000" stroke="none" 
	d="
M86.004097,157.000610 
	C86.825623,155.485687 87.647156,153.970749 88.742844,152.215729 
	C89.686653,157.723877 90.356300,163.472137 91.026733,169.595734 
	C87.664726,169.975082 84.301918,169.979080 80.484138,169.982361 
	C80.017769,169.541809 80.006378,169.101990 80.409958,168.334229 
	C83.108078,168.156464 85.076736,168.033707 85.194054,164.922775 
	C85.293869,162.275650 85.721497,159.640854 86.004097,157.000610 
z"/>
<path fill="#D09277" opacity="1.000000" stroke="none" 
	d="
M113.773193,155.549103 
	C116.707146,155.091705 118.748665,153.894150 119.354980,150.408600 
	C120.090874,150.440323 120.549156,150.720642 121.006721,151.441330 
	C120.688057,154.925964 120.370110,157.970230 120.052155,161.014481 
	C117.934235,161.431030 115.816315,161.847595 113.386177,162.111359 
	C113.325859,159.951981 113.577766,157.945389 113.773193,155.549103 
z"/>
<path fill="#F2DCCF" opacity="1.000000" stroke="none" 
	d="
M88.996529,149.554138 
	C88.126144,148.446609 86.568687,146.910172 86.518448,145.325958 
	C86.366684,140.540375 86.800140,135.736237 87.422676,130.471008 
	C89.210716,130.002625 90.581558,130.002594 91.952393,130.002548 
	C90.967789,136.369492 89.983185,142.736450 88.996529,149.554138 
z"/>
<path fill="#CA9376" opacity="1.000000" stroke="none" 
	d="
M112.899345,173.475403 
	C114.105270,173.442184 115.105789,173.764175 116.415787,174.157013 
	C119.378174,180.359131 118.121353,183.458405 112.341156,184.074982 
	C112.181587,180.701050 112.437759,177.265823 112.899345,173.475403 
z"/>
<path fill="#4E1913" opacity="1.000000" stroke="none" 
	d="
M116.224548,137.046371 
	C115.858284,137.199799 115.492020,137.353226 115.020630,137.799713 
	C113.546524,136.604401 112.177559,135.116013 110.647156,133.316650 
	C111.296333,132.370636 112.382248,131.896271 112.864563,131.069702 
	C114.171074,128.830673 115.221306,126.442093 116.852173,124.362137 
	C117.441032,127.633049 117.550903,130.654633 117.454529,133.911819 
	C116.907043,135.113739 116.565796,136.080048 116.224548,137.046371 
z"/>
<path fill="#E6D0D2" opacity="1.000000" stroke="none" 
	d="
M109.740700,172.073303 
	C107.264420,172.085846 104.183762,172.251938 104.479805,168.835373 
	C104.626762,167.139297 104.720207,163.399689 108.510254,164.781464 
	C105.900665,167.754883 106.798210,168.908951 109.972916,169.401093 
	C109.839470,170.541489 109.790085,171.307388 109.740700,172.073303 
z"/>
<path fill="#FCE1D0" opacity="1.000000" stroke="none" 
	d="
M91.979034,129.622223 
	C90.581558,130.002594 89.210716,130.002625 87.428207,130.005280 
	C86.688789,129.251038 86.361038,128.494186 86.424057,127.423607 
	C90.137650,126.312141 93.460457,125.514420 96.880493,124.838181 
	C96.977730,124.959663 97.173462,125.201614 97.173462,125.201614 
	C95.450867,126.548378 93.728271,127.895142 91.979034,129.622223 
z"/>
<path fill="#FCE1D0" opacity="1.000000" stroke="none" 
	d="
M106.125229,120.617294 
	C106.143799,121.134178 106.162369,121.651062 106.126137,122.551208 
	C103.356728,123.651489 100.642113,124.368515 97.550484,125.143578 
	C97.173462,125.201614 96.977730,124.959663 97.136230,124.602936 
	C99.346703,122.970215 101.398674,121.694214 103.499649,120.482925 
	C103.548668,120.547646 103.574959,120.387421 103.989059,120.503265 
	C104.977173,120.618500 105.551201,120.617897 106.125229,120.617294 
z"/>
<path fill="#F3CFD9" opacity="1.000000" stroke="none" 
	d="
M110.056976,169.026611 
	C106.798210,168.908951 105.900665,167.754883 108.711105,165.067444 
	C109.533211,165.308640 109.992050,165.615112 110.707230,166.311569 
	C110.661369,167.476562 110.359169,168.251587 110.056976,169.026611 
z"/>
<path fill="#6B3647" opacity="1.000000" stroke="none" 
	d="
M109.972916,169.401093 
	C110.359169,168.251587 110.661369,167.476562 111.000168,166.353729 
	C111.036774,166.005936 111.496857,165.950455 111.942642,166.127472 
	C112.577553,166.363678 112.766670,166.422882 112.948959,166.457245 
	C112.942123,166.432419 112.968056,166.464417 112.899620,166.829559 
	C112.580742,168.144318 112.330299,169.093903 111.961273,170.297485 
	C111.639381,171.064148 111.436058,171.576828 111.232742,172.089508 
	C110.983223,172.168839 110.733711,172.248184 110.112442,172.200409 
	C109.790085,171.307388 109.839470,170.541489 109.972916,169.401093 
z"/>
<path fill="#E6D0D2" opacity="1.000000" stroke="none" 
	d="
M111.498215,172.091339 
	C111.436058,171.576828 111.639381,171.064148 112.192276,170.401184 
	C112.724892,170.532822 112.907936,170.814713 113.093643,171.287369 
	C113.096321,171.478119 113.148064,171.856140 113.148064,171.856140 
	C113.148064,171.856140 112.979935,172.008118 112.896774,172.086792 
	C112.463638,172.141357 112.113663,172.117279 111.498215,172.091339 
z"/>
<path fill="#F3CFD9" opacity="1.000000" stroke="none" 
	d="
M112.955788,166.482071 
	C112.766670,166.422882 112.577553,166.363678 112.172646,166.099411 
	C112.051834,165.591446 112.218422,165.335083 112.636978,164.980927 
	C112.817352,164.836578 113.041504,165.046783 113.079849,165.459930 
	C113.064056,166.076065 113.009918,166.279068 112.955788,166.482071 
z"/>
<path fill="#B3FC07" opacity="1.000000" stroke="none" 
	d="
M31.461536,104.873192 
	C31.373451,104.174362 31.285366,103.475540 31.622643,102.295509 
	C32.212688,97.940880 32.304493,94.062668 32.558033,90.195061 
	C33.011177,83.282478 27.311275,75.343536 35.916229,69.772163 
	C35.916229,69.772163 36.290707,69.564545 36.889366,69.703552 
	C38.636826,69.922394 39.785625,70.002228 40.995892,70.395401 
	C42.371353,71.170219 43.685341,71.631699 45.331200,72.079712 
	C46.105595,72.074265 46.548115,72.082275 47.211781,72.342064 
	C48.595066,72.240051 50.165558,72.246925 50.850407,71.471558 
	C54.263798,67.606987 58.288345,70.705376 61.965790,70.231964 
	C61.741085,72.688576 61.583523,75.154037 61.261101,77.597755 
	C61.117142,78.688850 60.641834,79.736221 60.286243,80.904137 
	C58.108944,80.735359 56.117035,80.195312 54.269226,80.507683 
	C47.897675,81.584801 44.831364,87.271820 45.520042,94.359787 
	C46.102127,100.350662 45.639969,106.443001 45.639969,111.373337 
	C41.501549,112.533081 38.082348,113.491272 33.964371,114.645287 
	C33.964371,112.481789 34.260944,110.333778 33.856300,108.327179 
	C33.602009,107.066162 32.295815,106.017258 31.461536,104.873192 
z"/>
<path fill="#FDFDF7" opacity="1.000000" stroke="none" 
	d="
M19.612019,126.332306 
	C19.469713,123.793396 19.462969,121.645393 19.464867,119.122864 
	C19.642420,104.223351 19.784204,89.697929 20.012047,75.173851 
	C20.043112,73.193695 20.503965,71.220268 20.765823,69.243729 
	C21.510349,69.086334 22.254875,68.928947 22.999399,68.771553 
	C24.004492,71.200111 25.663170,73.564781 25.907862,76.067680 
	C27.417234,91.506638 28.760303,106.966545 29.796648,122.443733 
	C30.053774,126.283752 29.341473,130.314560 28.311577,134.047089 
	C27.947544,135.366409 25.308491,136.058014 23.284756,137.057846 
	C21.823463,133.627396 20.785522,130.175308 19.612019,126.332306 
z"/>
<path fill="#CF4C32" opacity="1.000000" stroke="none" 
	d="
M106.163742,120.260925 
	C105.551201,120.617897 104.977173,120.618500 103.986122,120.465134 
	C103.569092,120.311157 103.450638,120.418205 103.450638,120.418205 
	C101.398674,121.694214 99.346703,122.970215 97.039001,124.481461 
	C93.460457,125.514420 90.137650,126.312141 86.401283,127.113899 
	C85.987732,127.117935 85.812050,126.748543 85.593513,126.265640 
	C83.413277,122.695053 81.605743,119.487160 79.366768,116.615677 
	C78.888168,116.001854 76.844048,116.608704 75.521149,116.653214 
	C75.380867,116.095039 75.240585,115.536865 75.168526,114.242455 
	C74.992554,112.653069 75.018341,111.501823 74.459702,110.995880 
	C71.197807,108.041687 71.425903,104.702904 73.386612,101.087227 
	C74.502151,100.722145 75.282593,100.383270 76.474365,100.045593 
	C78.256836,100.034843 79.627983,100.022896 81.332184,100.017319 
	C82.109184,100.014725 82.553123,100.005760 83.447433,100.002151 
	C86.600609,99.998848 89.303398,99.990181 92.170959,100.213600 
	C92.909492,99.974411 93.483261,99.503136 94.518410,99.033585 
	C95.479004,99.034111 95.978226,99.035713 96.477409,99.031273 
	C111.139183,98.900925 110.648048,101.084892 106.709564,85.087959 
	C104.707344,76.955551 99.960068,69.666382 90.765388,68.571930 
	C76.684105,66.895828 68.091202,67.459854 65.407539,82.266495 
	C64.701790,86.160301 62.128082,86.734901 59.033524,86.997711 
	C59.028767,86.546173 59.024010,86.094635 59.379776,85.235802 
	C61.033730,83.397240 62.827072,82.169426 63.510597,80.489937 
	C64.721092,77.515633 65.257034,74.266785 66.176491,70.856232 
	C66.203232,70.397957 66.128738,70.216270 66.024628,70.059570 
	C65.995010,70.084557 66.040962,70.146965 66.040962,70.146965 
	C71.482979,68.634277 76.924995,67.121590 82.692749,65.410957 
	C84.066513,65.262352 85.114540,65.311684 86.842163,65.502403 
	C88.683189,65.703087 89.844627,65.762390 91.006073,65.821693 
	C91.350555,65.958290 91.695045,66.094887 92.493118,66.431084 
	C93.289879,66.665298 93.633049,66.699913 93.976212,66.734535 
	C94.331589,66.884262 94.686958,67.033981 95.554985,67.461868 
	C98.659737,69.623085 101.251831,71.506142 103.868744,73.569710 
	C103.893555,73.750229 104.180771,73.974548 104.241898,74.261299 
	C104.806152,75.143761 105.309265,75.739471 105.911133,76.643959 
	C106.359161,77.753502 106.708435,78.554276 107.057709,79.355049 
	C108.162453,83.988632 109.661201,88.576157 110.210365,93.274673 
	C110.496994,95.726921 109.112206,98.374527 107.700806,101.073151 
	C97.621315,101.575905 88.320564,101.881485 79.026604,102.328072 
	C74.583092,102.541588 73.831024,104.152832 76.150589,107.955139 
	C79.696968,113.768494 87.370316,114.277100 92.414658,109.006332 
	C93.411781,108.973763 94.073700,108.945816 94.660446,109.243820 
	C90.698502,111.951019 86.811714,114.332283 82.888687,116.735756 
	C88.151550,125.595276 88.998245,125.957359 98.665070,120.596115 
	C101.727707,118.897568 104.258636,116.240311 107.032898,114.021790 
	C106.756020,115.982712 106.479141,117.943626 106.163742,120.260925 
z"/>
<path fill="#C1EB34" opacity="1.000000" stroke="none" 
	d="
M66.075256,71.132828 
	C65.257034,74.266785 64.721092,77.515633 63.510597,80.489937 
	C62.827072,82.169426 61.033730,83.397240 59.376373,84.897141 
	C58.638584,84.962456 58.264721,84.959137 57.612061,84.939606 
	C53.650894,82.497925 50.139610,83.372002 49.134262,87.674004 
	C48.172092,91.791214 47.940594,96.196114 48.134521,100.440460 
	C48.465069,107.674896 49.380173,114.882614 49.768272,122.643188 
	C48.682552,123.470528 47.878258,123.756248 46.888371,123.982925 
	C46.702782,123.923874 46.315327,123.910324 46.287567,123.571564 
	C45.844795,122.471832 45.429783,121.710861 44.941269,120.654602 
	C44.772919,120.179993 44.678070,120.000671 44.633415,119.468987 
	C42.762093,117.822182 40.840580,116.527740 38.107628,114.686668 
	C38.107628,118.766548 38.107628,121.825455 37.760017,124.942413 
	C35.831181,124.609825 34.249950,124.219177 32.668720,123.828537 
	C32.464180,121.492249 32.259640,119.155960 32.213814,116.322388 
	C32.226906,115.560516 32.081284,115.295929 31.935661,115.031342 
	C31.679615,111.920288 31.423567,108.809227 31.314528,105.285683 
	C32.295815,106.017258 33.602009,107.066162 33.856300,108.327179 
	C34.260944,110.333778 33.964371,112.481789 33.964371,114.645287 
	C38.082348,113.491272 41.501549,112.533081 45.639969,111.373337 
	C45.639969,106.443001 46.102127,100.350662 45.520042,94.359787 
	C44.831364,87.271820 47.897675,81.584801 54.269226,80.507683 
	C56.117035,80.195312 58.108944,80.735359 60.286243,80.904137 
	C60.641834,79.736221 61.117142,78.688850 61.261101,77.597755 
	C61.583523,75.154037 61.741085,72.688576 61.965790,70.231964 
	C58.288345,70.705376 54.263798,67.606987 50.850407,71.471558 
	C50.165558,72.246925 48.595066,72.240051 47.375168,72.063187 
	C47.880199,70.690994 48.442989,69.849472 49.005775,69.007950 
	C49.702118,68.610573 50.398464,68.213203 51.737701,67.837173 
	C52.925076,67.536774 53.469555,67.215027 54.014038,66.893288 
	C58.124268,67.019714 62.560192,65.641533 65.956093,69.803467 
	C66.040962,70.146965 65.995010,70.084557 65.818390,70.128342 
	C65.786263,70.492355 65.930763,70.812592 66.075256,71.132828 
z"/>
<path fill="#9D5734" opacity="1.000000" stroke="none" 
	d="
M35.028191,196.536179 
	C34.399940,197.346634 33.773003,197.732513 33.096989,198.412247 
	C32.399361,197.085480 31.248148,195.485153 31.181719,193.841003 
	C30.647247,180.612549 30.342026,167.375031 29.899557,154.142517 
	C29.828064,152.004395 29.826601,149.725830 29.092360,147.775925 
	C27.415895,143.323776 28.250402,139.355942 31.098694,135.268631 
	C31.941088,136.752380 32.197330,138.425537 32.467300,140.188339 
	C32.982956,139.774155 33.474209,139.379578 34.337845,138.995956 
	C35.450871,139.029495 36.191513,139.052063 36.932178,139.458588 
	C37.049915,141.557892 37.891094,144.320221 37.168514,144.819519 
	C32.298210,148.184906 35.332397,152.196548 35.801392,156.395676 
	C35.813324,158.131226 35.804382,159.466385 35.447250,160.941132 
	C34.509014,166.427414 33.918968,171.774109 33.287941,177.462097 
	C33.297100,178.481262 33.347237,179.159134 33.397377,179.837021 
	C33.941418,185.261887 34.485458,190.686737 35.028191,196.536179 
z"/>
<path fill="#EFAC91" opacity="1.000000" stroke="none" 
	d="
M18.928761,139.926361 
	C20.296473,140.236252 21.664186,140.546158 23.373463,140.892822 
	C24.455647,143.035309 25.196266,145.141037 25.551392,147.623260 
	C24.456295,148.005524 23.746695,148.011292 23.037094,148.017059 
	C22.191317,146.994751 21.345541,145.972443 19.669334,143.946381 
	C19.349453,146.502014 19.180973,147.848038 18.599474,149.596664 
	C17.112669,150.003387 16.038883,150.007523 14.965096,150.011658 
	C14.335092,148.558090 13.705088,147.104538 13.040243,144.956268 
	C12.973422,143.794388 12.941440,143.327209 12.909457,142.860031 
	C12.880681,142.464203 12.851905,142.068390 13.089020,141.233109 
	C13.602371,140.461594 13.849833,140.129532 14.320984,139.855087 
	C14.544675,139.912704 14.994826,140.016632 15.024737,140.334351 
	C16.346020,140.410156 17.637390,140.168259 18.928761,139.926361 
z"/>
<path fill="#C1EB34" opacity="1.000000" stroke="none" 
	d="
M35.511208,69.711647 
	C27.311275,75.343536 33.011177,83.282478 32.558033,90.195061 
	C32.304493,94.062668 32.212688,97.940880 31.688034,101.945938 
	C30.918947,99.648415 30.509832,97.219261 30.358204,94.141357 
	C30.349222,91.988953 30.082756,90.485291 29.816290,88.981628 
	C29.556883,86.264038 29.297474,83.546448 29.265713,80.256523 
	C29.279543,79.117226 29.065725,78.550262 28.851906,77.983299 
	C28.818962,76.279427 28.786018,74.575554 29.069790,72.332474 
	C29.925262,71.082191 30.464020,70.371109 31.002777,69.660034 
	C32.370579,69.657066 33.738380,69.654099 35.511208,69.711647 
z"/>
<path fill="#D7AD95" opacity="1.000000" stroke="none" 
	d="
M23.029385,148.415039 
	C23.746695,148.011292 24.456295,148.005524 25.519302,148.000320 
	C25.821943,160.171967 25.771175,172.343063 25.494289,184.878967 
	C25.177931,183.761093 25.087692,182.278427 24.994171,180.348419 
	C24.988438,179.203293 24.985987,178.505493 24.991236,177.104279 
	C24.669395,175.592117 24.339855,174.783356 24.010315,173.974609 
	C23.994669,168.257401 23.979021,162.540192 23.927290,156.340729 
	C23.601360,153.509979 23.311518,151.161484 23.029385,148.415039 
z"/>
<path fill="#622E0F" opacity="1.000000" stroke="none" 
	d="
M32.887760,129.026306 
	C32.887760,129.026306 32.994785,128.543915 33.402435,128.273224 
	C35.516300,127.035019 37.222511,126.067505 39.004074,124.850914 
	C39.695274,124.730705 40.311131,124.859581 40.828537,125.261757 
	C41.092594,126.711052 41.455097,127.887054 41.814629,129.345428 
	C41.814316,130.005203 41.816975,130.382584 41.551750,130.812866 
	C40.828270,131.135483 40.372665,131.405197 39.550770,131.691620 
	C37.725761,132.367126 36.267044,133.025925 34.810139,133.683914 
	C34.117340,132.005371 33.502552,130.515839 32.887760,129.026306 
z"/>
<path fill="#C26C4F" opacity="1.000000" stroke="none" 
	d="
M32.585712,129.221436 
	C33.502552,130.515839 34.117340,132.005371 34.810139,133.683914 
	C36.267044,133.025925 37.725761,132.367126 39.573166,132.073334 
	C39.965130,132.941742 39.968407,133.445145 39.983574,133.966644 
	C39.995461,133.984756 39.953648,133.973907 39.661770,134.000244 
	C38.909946,134.343765 38.450001,134.660919 37.740570,134.981689 
	C37.491089,134.985291 36.992275,134.999512 36.675236,135.136063 
	C35.560619,136.510071 34.763042,137.747528 33.965462,138.984985 
	C33.474209,139.379578 32.982956,139.774155 32.467300,140.188339 
	C32.197330,138.425537 31.941088,136.752380 31.357771,135.021210 
	C31.448353,133.114334 31.866009,131.265457 32.585712,129.221436 
z"/>
<path fill="#D2ED6E" opacity="1.000000" stroke="none" 
	d="
M38.928726,125.099998 
	C37.222511,126.067505 35.516300,127.035019 33.438133,128.028763 
	C32.792637,126.827118 32.519089,125.599243 32.457130,124.099953 
	C34.249950,124.219177 35.831181,124.609825 37.963524,125.002632 
	C38.514641,125.004799 38.928726,125.099998 38.928726,125.099998 
z"/>
<path fill="#D2ED6E" opacity="1.000000" stroke="none" 
	d="
M40.934422,70.082062 
	C39.785625,70.002228 38.636826,69.922394 37.095009,69.643997 
	C39.029327,69.391815 41.356670,69.338211 43.531265,69.613419 
	C42.563820,69.988846 41.749119,70.035454 40.934422,70.082062 
z"/>
<path fill="#C1EB34" opacity="1.000000" stroke="none" 
	d="
M40.995892,70.395401 
	C41.749119,70.035454 42.563820,69.988846 43.783920,69.877838 
	C44.189320,69.813446 44.573414,69.948631 44.758743,70.354019 
	C44.962490,71.204002 44.980911,71.648582 44.999332,72.093170 
	C43.685341,71.631699 42.371353,71.170219 40.995892,70.395401 
z"/>
<path fill="#D2ED6E" opacity="1.000000" stroke="none" 
	d="
M29.595299,89.246391 
	C30.082756,90.485291 30.349222,91.988953 30.364388,93.737396 
	C29.866823,92.491837 29.620565,91.001495 29.595299,89.246391 
z"/>
<path fill="#D2ED6E" opacity="1.000000" stroke="none" 
	d="
M45.331200,72.079712 
	C44.980911,71.648582 44.962490,71.204002 44.948494,70.427826 
	C46.070747,69.749893 47.188580,69.403557 48.656097,69.032585 
	C48.442989,69.849472 47.880199,70.690994 47.154022,71.811401 
	C46.548115,72.082275 46.105595,72.074265 45.331200,72.079712 
z"/>
<path fill="#D2ED6E" opacity="1.000000" stroke="none" 
	d="
M30.792336,69.473015 
	C30.464020,70.371109 29.925262,71.082191 29.058636,71.907120 
	C29.347809,71.109306 29.964853,70.197647 30.792336,69.473015 
z"/>
<path fill="#D2ED6E" opacity="1.000000" stroke="none" 
	d="
M28.657398,78.152206 
	C29.065725,78.550262 29.279543,79.117226 29.270988,79.840668 
	C28.853374,79.438477 28.658131,78.879799 28.657398,78.152206 
z"/>
<path fill="#D2ED6E" opacity="1.000000" stroke="none" 
	d="
M53.835167,66.698669 
	C53.469555,67.215027 52.925076,67.536774 52.045521,67.779404 
	C52.359062,67.301552 53.007675,66.902802 53.835167,66.698669 
z"/>
<path fill="#D2ED6E" opacity="1.000000" stroke="none" 
	d="
M31.921234,115.281067 
	C32.081284,115.295929 32.226906,115.560516 32.205902,115.919159 
	C31.995121,115.852409 31.950964,115.691597 31.921234,115.281067 
z"/>
<path fill="#FDFCF9" opacity="1.000000" stroke="none" 
	d="
M167.998535,172.992523 
	C166.928909,179.509827 165.859283,186.027130 164.534973,192.802704 
	C161.788193,193.103165 160.934341,192.469467 161.542969,189.532822 
	C162.763092,183.645721 164.055954,177.589966 161.044434,171.037933 
	C161.002808,169.889481 161.002014,169.444443 161.329483,168.998474 
	C162.095474,168.996048 162.533188,168.994553 163.083130,169.220093 
	C163.470551,169.633179 163.745758,169.819199 164.312729,170.153122 
	C165.735840,171.198196 166.867188,172.095352 167.998535,172.992523 
z"/>
<path fill="#FAF9FB" opacity="1.000000" stroke="none" 
	d="
M144.253906,171.997986 
	C142.047714,171.673355 140.095444,171.346085 137.585693,170.700287 
	C136.326874,170.410416 135.625504,170.439056 134.804764,170.200256 
	C135.419861,168.300446 136.154343,166.668091 137.315674,165.024872 
	C140.807083,165.692764 144.979691,164.067352 145.741470,169.192627 
	C145.324020,170.261047 145.169800,171.124115 145.015594,171.987183 
	C144.846313,171.989899 144.677063,171.992615 144.253906,171.997986 
z"/>
<path fill="#EFEFEF" opacity="1.000000" stroke="none" 
	d="
M145.458038,171.988510 
	C145.169800,171.124115 145.324020,170.261047 146.115372,169.196564 
	C147.500595,168.994110 148.248672,168.993088 149.346710,168.998322 
	C150.806290,169.670853 151.915924,170.337128 153.017700,171.251190 
	C153.009857,171.498978 152.997833,171.994659 152.997833,171.994659 
	C150.632050,171.993057 148.266266,171.991440 145.458038,171.988510 
z"/>
<path fill="#EFEFEF" opacity="1.000000" stroke="none" 
	d="
M161.001221,168.999390 
	C161.002014,169.444443 161.002808,169.889481 161.002075,170.668365 
	C159.285141,171.003769 157.569717,171.005356 155.427261,171.003830 
	C155.000214,171.000732 154.995728,171.002441 154.994705,170.668091 
	C154.995392,169.887924 154.997116,169.442139 155.427444,168.997040 
	C157.571106,168.998291 159.286163,168.998840 161.001221,168.999390 
z"/>
<path fill="#FDFCF9" opacity="1.000000" stroke="none" 
	d="
M153.168030,172.224091 
	C152.997833,171.994659 153.009857,171.498978 153.345734,171.250519 
	C154.119644,171.002182 154.557693,171.002304 154.995728,171.002441 
	C154.995728,171.002441 155.000214,171.000732 154.998627,171.007248 
	C154.444092,171.493683 153.891159,171.973602 153.168030,172.224091 
z"/>
<path fill="#EEB49B" opacity="1.000000" stroke="none" 
	d="
M14.968500,150.436462 
	C16.038883,150.007523 17.112669,150.003387 18.588074,149.999207 
	C17.923874,151.985413 16.858055,153.971664 15.418086,155.951965 
	C15.019926,154.251099 14.995915,152.556198 14.968500,150.436462 
z"/>
<path fill="#EDCCB1" opacity="1.000000" stroke="none" 
	d="
M194.084656,148.086288 
	C193.807312,148.557831 193.529968,149.029358 193.186310,149.766006 
	C192.501343,150.833420 191.882721,151.635742 191.149780,152.717957 
	C189.646347,156.695267 188.257248,160.392670 186.533386,164.528809 
	C185.790771,164.985886 185.382919,165.004227 184.973724,164.566406 
	C184.965240,161.079849 184.958099,158.049423 184.973511,154.684341 
	C185.010635,153.902985 185.025223,153.456299 185.375214,152.958267 
	C186.468536,151.600433 187.226425,150.293945 187.986679,148.656830 
	C187.985947,147.885193 187.982849,147.444183 188.282425,146.859756 
	C191.071075,144.552399 192.976151,144.720459 194.084656,148.086288 
z"/>
<path fill="#EEB49B" opacity="1.000000" stroke="none" 
	d="
M14.097295,139.797470 
	C13.849833,140.129532 13.602371,140.461594 13.070610,140.936035 
	C10.697894,138.855865 8.609477,136.633331 6.759227,134.204987 
	C7.713506,134.007248 8.429619,134.015335 9.834319,134.144531 
	C11.714370,136.109589 12.905833,137.953537 14.097295,139.797470 
z"/>
<path fill="#EEB49B" opacity="1.000000" stroke="none" 
	d="
M12.626165,142.942596 
	C12.941440,143.327209 12.973422,143.794388 13.011541,144.610840 
	C12.792744,144.315125 12.567809,143.670151 12.626165,142.942596 
z"/>
<path fill="#59CBF2" opacity="1.000000" stroke="none" 
	d="
M114.211212,120.016838 
	C113.878502,121.118744 113.756630,122.360283 113.147926,123.279396 
	C112.639503,124.047096 111.589081,124.794197 110.724670,124.862022 
	C110.198448,124.903313 109.070793,123.632851 109.079582,122.962975 
	C109.107353,120.845215 109.325310,118.675049 109.867195,116.630508 
	C110.948029,112.552490 113.116463,108.828377 110.854996,104.203537 
	C110.109131,102.678215 112.982971,99.693542 112.710297,97.567413 
	C111.922318,91.423218 110.375435,85.376350 108.730423,78.686508 
	C108.341934,75.117210 108.349533,72.152657 108.357117,69.188110 
	C108.721397,69.143631 109.085670,69.099159 109.713715,68.973419 
	C112.275131,68.949432 114.575539,69.105331 116.869843,69.041618 
	C120.635406,68.937050 123.889008,69.616798 125.958092,73.794464 
	C127.056290,73.574966 127.512215,73.253731 127.968140,72.932487 
	C127.968140,72.932487 127.996384,72.998741 128.361908,72.951378 
	C130.787292,72.584480 132.847137,72.264946 135.259949,72.108658 
	C139.529205,75.734306 138.988220,78.066063 133.161301,81.465439 
	C134.380386,81.716110 135.288544,81.902847 135.943588,82.369354 
	C130.523285,83.569099 125.673370,85.121613 125.511749,91.499054 
	C125.331642,98.606216 125.634651,105.725639 125.531776,113.235146 
	C125.398567,114.204506 125.460953,114.778435 125.313507,115.650520 
	C123.685242,115.986931 122.009377,116.536179 120.893433,115.973991 
	C116.335205,113.677658 114.485222,114.692032 114.211212,120.016838 
z"/>
<path fill="#66C0C4" opacity="1.000000" stroke="none" 
	d="
M159.838593,81.055283 
	C156.797668,91.869324 157.649536,102.424744 160.189407,113.873886 
	C160.141800,117.754402 160.341370,120.771568 159.919342,123.699135 
	C159.750549,124.870018 158.267212,126.698738 157.287231,126.762268 
	C152.408813,127.078522 147.498734,126.906700 143.254501,126.906700 
	C142.455704,112.074310 141.703827,98.113312 141.346771,84.155655 
	C142.502533,85.784325 143.880600,87.394379 143.921249,89.037521 
	C144.137054,97.759888 143.985413,106.490677 144.021713,115.218307 
	C144.066330,125.944252 142.141541,125.068199 154.947952,125.018837 
	C155.279694,125.017563 155.740540,125.119232 155.920380,124.945000 
	C156.657715,124.230644 157.878174,123.442253 157.898041,122.655296 
	C158.006699,118.350861 158.824661,113.695770 157.505081,109.803177 
	C155.017944,102.466484 154.599350,95.348015 155.992905,87.860291 
	C156.652527,84.316025 156.656830,80.649780 156.972565,77.025078 
	C156.987106,77.011353 157.015518,76.983025 157.015518,76.983025 
	C157.875275,78.258789 158.735016,79.534561 159.838593,81.055283 
z"/>
<path fill="#66C0C4" opacity="1.000000" stroke="none" 
	d="
M134.906982,71.945412 
	C132.847137,72.264946 130.787292,72.584480 128.335892,72.926468 
	C135.535782,66.217003 146.871994,67.220482 153.003494,72.565918 
	C153.064758,72.941292 152.986389,73.018669 152.600632,73.000618 
	C149.764709,72.128914 147.335052,70.656029 144.859329,70.574043 
	C141.560638,70.464806 138.226120,71.437485 134.906982,71.945412 
z"/>
<path fill="#66C0C4" opacity="1.000000" stroke="none" 
	d="
M154.174286,73.812691 
	C155.096664,74.649574 156.019028,75.486465 156.978455,76.653183 
	C157.015518,76.983025 156.987106,77.011353 156.639557,76.972824 
	C155.525055,75.958473 154.758118,74.982658 154.037109,73.958458 
	C154.083054,73.910072 154.174286,73.812691 154.174286,73.812691 
z"/>
<path fill="#66C0C4" opacity="1.000000" stroke="none" 
	d="
M127.680893,72.947495 
	C127.512215,73.253731 127.056290,73.574966 126.304855,73.865875 
	C126.470795,73.544540 126.932220,73.253525 127.680893,72.947495 
z"/>
<path fill="#66C0C4" opacity="1.000000" stroke="none" 
	d="
M153.105133,72.903854 
	C153.470688,72.950279 153.738403,73.123154 154.061462,73.598862 
	C154.174286,73.812691 154.083054,73.910072 153.820892,73.848755 
	C153.289856,73.589340 153.099075,73.333076 152.986389,73.018669 
	C152.986389,73.018669 153.064758,72.941292 153.105133,72.903854 
z"/>
<path fill="#F1F9F4" opacity="1.000000" stroke="none" 
	d="
M140.951965,84.152321 
	C141.703827,98.113312 142.455704,112.074310 143.254501,126.906700 
	C147.498734,126.906700 152.408813,127.078522 157.287231,126.762268 
	C158.267212,126.698738 159.750549,124.870018 159.919342,123.699135 
	C160.341370,120.771568 160.141800,117.754402 160.226440,114.282959 
	C162.269821,116.182762 164.278397,118.572792 166.623245,121.014854 
	C167.503250,121.591011 168.046997,122.115143 168.814072,123.095360 
	C169.387527,123.866226 169.737656,124.181000 170.087784,124.495781 
	C170.477478,125.056305 170.867157,125.616829 171.397583,126.851166 
	C172.518936,132.226898 174.617722,136.832855 172.417603,141.893738 
	C169.293686,145.644714 168.290329,149.588684 169.993362,154.254166 
	C170.034973,154.669495 170.074158,154.832855 170.103455,155.454697 
	C169.723053,160.608856 169.352509,165.304550 168.981979,170.000229 
	C168.821915,170.857483 168.661835,171.714752 168.250153,172.782257 
	C166.867188,172.095352 165.735840,171.198196 164.308136,169.899902 
	C164.010559,169.329971 164.009338,169.161179 164.423584,168.978134 
	C165.559540,167.908707 166.814438,166.902405 166.916077,165.790649 
	C167.736526,156.816788 168.318771,147.821396 169.033630,138.837341 
	C169.136871,137.540009 169.684677,136.279984 169.807907,134.981918 
	C170.072891,132.190720 169.420792,130.113480 165.973526,129.963318 
	C161.374557,129.763016 156.790237,129.214401 152.191055,129.034012 
	C148.016418,128.870285 143.315903,127.917007 142.144135,134.053635 
	C142.061264,134.487625 140.749100,134.686890 140.007553,134.560104 
	C140.272430,129.549088 140.055634,125.189491 134.762421,122.834717 
	C134.357956,122.117287 134.208984,121.554840 134.305573,120.786270 
	C134.029648,119.715836 133.508163,118.851509 132.775314,117.726151 
	C130.285080,115.923309 128.006226,114.381516 125.727371,112.839722 
	C125.634651,105.725639 125.331642,98.606216 125.511749,91.499054 
	C125.673370,85.121613 130.523285,83.569099 136.256989,82.428505 
	C138.199646,82.856033 139.575806,83.504173 140.951965,84.152321 
z"/>
<path fill="#FB8347" opacity="1.000000" stroke="none" 
	d="
M44.954006,167.035553 
	C44.964249,167.467438 44.974487,167.899307 44.760315,168.828461 
	C44.995045,169.901169 45.454189,170.476593 45.913330,171.052017 
	C45.934471,171.477615 45.955616,171.903198 45.974876,172.647797 
	C45.316532,174.061493 44.660069,175.156174 43.999100,176.626328 
	C43.001019,176.497574 42.007446,175.993378 41.011215,175.484619 
	C40.555767,176.629654 40.102974,177.779251 39.176003,178.620193 
	C38.672844,176.441559 38.331795,174.509018 38.691082,172.716873 
	C39.179123,170.282471 38.860130,168.876465 36.042889,168.603607 
	C36.036152,167.499359 36.042694,166.762863 36.402275,165.980713 
	C38.378258,164.930725 40.001209,163.926376 41.531517,162.979370 
	C42.830105,164.518402 43.892056,165.776978 44.954006,167.035553 
z"/>
<path fill="#EF763D" opacity="1.000000" stroke="none" 
	d="
M36.056160,168.971344 
	C38.860130,168.876465 39.179123,170.282471 38.691082,172.716873 
	C38.331795,174.509018 38.672844,176.441559 38.863358,178.667572 
	C39.020981,179.765518 39.017067,180.507401 38.734039,181.864792 
	C38.617043,185.674210 38.779156,188.868149 38.941269,192.062073 
	C37.958748,189.338089 37.019375,186.597412 35.975479,183.897156 
	C35.454861,182.550476 34.742996,181.277756 33.757507,179.904297 
	C33.347237,179.159134 33.297100,178.481262 33.616238,177.329681 
	C34.675728,174.227768 35.365944,171.599564 36.056160,168.971344 
z"/>
<path fill="#FB8347" opacity="1.000000" stroke="none" 
	d="
M38.949280,192.427475 
	C38.779156,188.868149 38.617043,185.674210 38.734879,182.236115 
	C39.674198,186.027603 40.333569,190.063263 40.983307,194.558929 
	C40.524406,195.012756 40.075138,195.006577 39.287674,194.995392 
	C38.952084,194.257874 38.954693,193.525375 38.949280,192.427475 
z"/>
<path fill="#FB8347" opacity="1.000000" stroke="none" 
	d="
M44.017147,177.304291 
	C44.488617,177.554428 44.979279,178.103470 45.242744,178.824142 
	C44.689144,178.531586 44.362743,178.067383 44.017147,177.304291 
z"/>
<path fill="#8F7582" opacity="1.000000" stroke="none" 
	d="
M136.888824,165.035721 
	C136.154343,166.668091 135.419861,168.300446 134.799484,170.207230 
	C134.913589,170.481644 134.891541,170.494812 134.891541,170.494812 
	C134.530914,171.242264 134.170288,171.989731 133.491837,172.803925 
	C132.999649,171.667938 132.825287,170.465240 132.406616,168.983978 
	C132.060226,168.387131 131.848099,168.183350 131.275635,167.714066 
	C131.693497,166.540863 132.361649,165.747665 132.963806,165.326340 
	C133.110565,166.374130 133.323318,167.050064 133.536072,167.725983 
	C133.963669,167.362762 134.391281,166.999542 134.818878,166.636322 
	C134.197342,166.098022 133.575821,165.559738 133.030731,164.859238 
	C133.107162,164.697037 133.097427,164.338547 133.429626,164.272385 
	C134.804153,164.482727 135.846497,164.759216 136.888824,165.035721 
z"/>
<path fill="#ECCEC6" opacity="1.000000" stroke="none" 
	d="
M133.722046,190.867828 
	C133.083786,190.335358 132.559738,189.453796 132.110382,188.223572 
	C132.735458,188.756195 133.285858,189.637466 133.722046,190.867828 
z"/>
<path fill="#ECCEC6" opacity="1.000000" stroke="none" 
	d="
M134.811844,196.797836 
	C134.502014,196.584000 134.259430,196.071655 134.043823,195.265076 
	C134.340225,195.480347 134.609650,195.989822 134.811844,196.797836 
z"/>
<path fill="#99808D" opacity="1.000000" stroke="none" 
	d="
M135.271713,170.711288 
	C134.891541,170.494812 134.913589,170.481644 134.918869,170.474670 
	C135.625504,170.439056 136.326874,170.410416 137.190338,170.717560 
	C136.785599,171.011475 136.218750,170.969620 135.271713,170.711288 
z"/>
<path fill="#F1DAC5" opacity="1.000000" stroke="none" 
	d="
M184.975052,165.022552 
	C185.382919,165.004227 185.790771,164.985886 186.500488,164.944382 
	C186.866959,169.304062 186.931549,173.686890 186.978989,178.534210 
	C186.257858,178.346481 185.033676,177.754898 184.949203,177.030441 
	C184.712418,174.999710 184.946075,172.914124 184.992737,170.017334 
	C184.972595,168.112381 184.953873,167.040237 184.948395,165.731995 
	C184.961655,165.495895 184.975052,165.022552 184.975052,165.022552 
z"/>
<path fill="#D09474" opacity="1.000000" stroke="none" 
	d="
M24.009596,174.321991 
	C24.339855,174.783356 24.669395,175.592117 24.995720,176.753754 
	C24.664629,176.294220 24.336754,175.481796 24.009596,174.321991 
z"/>
<path fill="#FCE7CE" opacity="1.000000" stroke="none" 
	d="
M83.994888,172.423401 
	C87.978012,170.331253 90.193573,171.086121 90.241806,175.975464 
	C90.269928,178.827057 91.098679,181.670761 91.297226,184.762802 
	C88.595566,183.720703 86.326569,179.407806 83.271225,184.802307 
	C83.330093,180.948364 83.664055,176.897568 83.994888,172.423401 
z"/>
<path fill="#FDE7CE" opacity="1.000000" stroke="none" 
	d="
M184.935150,165.968109 
	C184.953873,167.040237 184.972595,168.112381 184.988724,169.586304 
	C184.345428,170.599762 183.235779,171.144791 183.137848,171.833633 
	C182.318771,177.595322 181.685638,183.383438 180.992126,189.585144 
	C179.871902,190.245224 178.755997,190.483749 177.320053,190.860519 
	C177.538773,186.232254 178.222198,181.474472 178.511749,176.692856 
	C178.581680,175.538101 177.349106,174.304489 176.362106,172.677719 
	C176.337921,170.832947 176.667130,169.417603 177.316803,168.138641 
	C180.069916,167.506058 182.502533,166.737091 184.935150,165.968109 
z"/>
<path fill="#FDD5C0" opacity="1.000000" stroke="none" 
	d="
M176.996338,168.002243 
	C176.667130,169.417603 176.337921,170.832947 176.011688,172.619995 
	C176.902069,177.808395 175.668335,181.854309 171.346924,184.953552 
	C171.009308,183.291031 171.011444,181.576584 171.303558,179.206421 
	C172.054352,176.357269 172.515167,174.163879 172.978439,171.574203 
	C172.991531,170.121262 173.002167,169.064606 173.362885,167.999329 
	C174.471252,167.332245 175.229523,166.673752 176.241959,166.009857 
	C176.665573,166.002319 176.835037,166.000214 177.001282,166.332092 
	C176.997467,167.111481 176.996902,167.556854 176.996338,168.002243 
z"/>
<path fill="#FDE7CE" opacity="1.000000" stroke="none" 
	d="
M176.678192,191.041290 
	C176.547104,191.638901 176.095444,192.277802 175.323410,192.958038 
	C175.454575,192.360443 175.906097,191.721497 176.678192,191.041290 
z"/>
<path fill="#FBDDC2" opacity="1.000000" stroke="none" 
	d="
M5.547427,165.991089 
	C5.518911,166.906464 5.154418,167.903824 4.454473,168.981781 
	C4.483164,168.065933 4.847307,167.069504 5.547427,165.991089 
z"/>
<path fill="#FAD9C5" opacity="1.000000" stroke="none" 
	d="
M85.958099,156.618591 
	C85.721497,159.640854 85.293869,162.275650 85.194054,164.922775 
	C85.076736,168.033707 83.108078,168.156464 80.412315,168.004059 
	C79.999695,168.001831 79.970802,167.526016 80.038223,166.921692 
	C80.400169,163.540543 80.694695,160.763748 80.989220,157.986938 
	C80.873528,155.191269 80.757835,152.395584 80.367531,149.315643 
	C80.091476,147.655655 80.090034,146.279968 80.445351,144.469025 
	C81.481438,143.982666 82.160767,143.931549 82.840096,143.880432 
	C83.864098,147.999146 84.888107,152.117874 85.958099,156.618591 
z"/>
<path fill="#FCCDBC" opacity="1.000000" stroke="none" 
	d="
M80.711807,157.861267 
	C80.694695,160.763748 80.400169,163.540543 80.032883,166.683411 
	C79.173492,167.099777 78.386848,167.150116 77.375404,167.087799 
	C77.418793,163.600616 77.686981,160.226074 77.944839,156.426163 
	C78.767807,156.579056 79.601105,157.157318 80.711807,157.861267 
z"/>
<path fill="#8A3322" opacity="1.000000" stroke="none" 
	d="
M14.320986,139.855087 
	C12.905833,137.953537 11.714370,136.109589 10.192339,134.150146 
	C10.511538,132.280777 11.161304,130.526947 11.777311,128.418182 
	C12.597908,127.989281 13.452266,127.915298 14.714544,127.709938 
	C15.670427,129.777283 16.218390,131.975998 16.904812,134.882355 
	C16.360458,137.065536 15.677644,138.541077 14.994827,140.016632 
	C14.994826,140.016632 14.544675,139.912704 14.320986,139.855087 
z"/>
<path fill="#EAB39F" opacity="1.000000" stroke="none" 
	d="
M173.012787,168.007935 
	C173.002167,169.064606 172.991531,170.121262 172.607574,171.583160 
	C171.491318,172.003311 170.748398,172.018219 170.005493,172.033142 
	C169.831894,171.467850 169.658295,170.902573 169.233337,170.168762 
	C169.352509,165.304550 169.723053,160.608856 170.251923,155.351654 
	C170.270493,154.527496 170.130707,154.264832 169.990936,154.002182 
	C168.290329,149.588684 169.293686,145.644714 172.471298,142.259415 
	C171.555847,145.722443 173.271042,146.224625 175.967560,146.258255 
	C175.934402,148.207535 175.965149,149.820877 175.723450,151.392303 
	C174.870331,156.938599 173.923859,162.470551 173.012787,168.007935 
z"/>
<path fill="#F9C9B1" opacity="1.000000" stroke="none" 
	d="
M170.006653,172.467041 
	C170.748398,172.018219 171.491318,172.003311 172.605103,171.979431 
	C172.515167,174.163879 172.054352,176.357269 171.307861,178.778107 
	C170.852417,179.003784 170.682648,179.001999 170.258270,178.997955 
	C170.005051,176.964127 170.006439,174.932541 170.006653,172.467041 
z"/>
<path fill="#F7DCBF" opacity="1.000000" stroke="none" 
	d="
M194.384567,147.912048 
	C192.976151,144.720459 191.071075,144.552399 188.059998,146.744293 
	C187.343002,146.502930 187.151077,146.233627 187.123108,145.611954 
	C187.516159,143.181610 187.745270,141.103607 187.984955,138.688477 
	C187.996811,137.901901 187.998093,137.452469 188.004028,136.978241 
	C188.008652,136.953430 188.023727,136.905273 188.318893,137.050583 
	C190.716949,136.230621 192.819839,135.265350 193.865463,134.785385 
	C195.745316,131.059174 189.636002,128.874023 194.074066,125.953445 
	C194.244186,125.957443 194.414322,125.961441 194.840027,125.984909 
	C195.734695,127.759293 196.373779,129.514206 197.040863,131.633041 
	C197.279678,132.800415 197.490494,133.603851 197.818604,134.701111 
	C197.972168,135.811615 197.771179,136.735199 198.083206,137.427582 
	C200.120117,141.947327 198.638336,145.231979 194.384567,147.912048 
z"/>
<path fill="#F1C49B" opacity="1.000000" stroke="none" 
	d="
M194.061096,125.747681 
	C189.636002,128.874023 195.745316,131.059174 193.865463,134.785385 
	C192.819839,135.265350 190.716949,136.230621 188.264679,136.674637 
	C187.255096,131.805466 186.594925,127.457535 185.969421,123.056610 
	C186.004089,123.003616 186.075989,122.899330 186.075989,122.899330 
	C188.480896,123.631935 190.885788,124.364540 193.634903,125.116272 
	C193.979111,125.135391 194.048141,125.541916 194.061096,125.747681 
z"/>
<path fill="#E6FCE5" opacity="1.000000" stroke="none" 
	d="
M107.968750,69.135612 
	C108.349533,72.152657 108.341934,75.117210 108.434807,78.520073 
	C108.294556,79.045784 108.053848,79.133171 107.435425,79.287804 
	C106.708435,78.554276 106.359161,77.753502 105.946823,76.306961 
	C105.316101,75.098976 104.748436,74.536766 104.180771,73.974548 
	C104.180771,73.974548 103.893555,73.750229 103.918571,73.155243 
	C103.946198,71.732903 103.948814,70.905556 103.951424,70.078209 
	C105.161072,69.746513 106.370728,69.414810 107.968750,69.135612 
z"/>
<path fill="#FAF5CD" opacity="1.000000" stroke="none" 
	d="
M103.642578,69.899422 
	C103.948814,70.905556 103.946198,71.732903 103.893761,72.974716 
	C101.251831,71.506142 98.659737,69.623085 95.845444,67.474335 
	C98.193413,68.045967 100.763565,68.883301 103.642578,69.899422 
z"/>
<path fill="#FAF5CD" opacity="1.000000" stroke="none" 
	d="
M90.936600,65.486206 
	C89.844627,65.762390 88.683189,65.703087 87.232773,65.450394 
	C88.251572,65.221581 89.559349,65.186150 90.936600,65.486206 
z"/>
<path fill="#FAF5CD" opacity="1.000000" stroke="none" 
	d="
M93.802269,66.533997 
	C93.633049,66.699913 93.289879,66.665298 92.772949,66.426926 
	C92.942238,66.259933 93.285278,66.296692 93.802269,66.533997 
z"/>
<path fill="#B06440" opacity="1.000000" stroke="none" 
	d="
M50.298409,135.884140 
	C44.543678,141.743195 41.408913,148.074142 43.607231,156.506836 
	C42.473621,155.232178 41.740490,153.614548 41.006645,151.547501 
	C40.988712,146.453873 40.089577,141.592560 44.935925,138.339188 
	C45.479240,137.974457 45.005047,136.094025 45.160301,134.650818 
	C46.208504,133.277634 47.099117,132.170090 48.197052,131.256256 
	C49.249752,129.970886 50.095135,128.491806 51.323090,127.008438 
	C52.470566,126.998108 53.235470,126.992058 54.000378,126.986008 
	C54.000378,126.986008 54.003658,126.997231 53.988754,127.394821 
	C54.009563,128.581711 54.045277,129.371017 54.080990,130.160309 
	C52.872448,131.973709 51.663902,133.787109 50.298409,135.884140 
z"/>
<path fill="#B47A54" opacity="1.000000" stroke="none" 
	d="
M53.955978,126.652496 
	C53.235470,126.992058 52.470566,126.998108 51.122009,127.008133 
	C50.538357,127.012108 50.134964,127.013313 49.923325,126.751602 
	C48.832447,125.673920 47.953205,124.857948 47.073963,124.041977 
	C47.878258,123.756248 48.682552,123.470528 49.788109,123.061081 
	C51.363438,124.064575 52.637512,125.191788 53.955978,126.652496 
z"/>
<path fill="#B47A54" opacity="1.000000" stroke="none" 
	d="
M54.346802,129.978043 
	C54.045277,129.371017 54.009563,128.581711 53.993279,127.398422 
	C54.212677,127.934883 54.412643,128.865326 54.346802,129.978043 
z"/>
<path fill="#F73312" opacity="1.000000" stroke="none" 
	d="
M80.999130,100.010941 
	C79.627983,100.022896 78.256836,100.034843 76.124527,100.031616 
	C74.592751,100.382103 73.822128,100.747772 73.051506,101.113441 
	C71.425903,104.702904 71.197807,108.041687 74.459702,110.995880 
	C75.018341,111.501823 74.992554,112.653069 75.129036,113.811768 
	C74.661301,117.251564 74.301270,120.385818 73.935913,123.566391 
	C64.126167,123.551727 54.425152,100.741959 58.967098,87.411324 
	C62.128082,86.734901 64.701790,86.160301 65.407539,82.266495 
	C68.091202,67.459854 76.684105,66.895828 90.765388,68.571930 
	C99.960068,69.666382 104.707344,76.955551 106.709564,85.087959 
	C110.648048,101.084892 111.139183,98.900925 96.477409,99.031273 
	C95.978226,99.035713 95.479004,99.034111 94.213989,99.039803 
	C92.967514,99.356689 92.486855,99.669098 92.006195,99.981514 
	C89.303398,99.990181 86.600609,99.998848 83.302567,99.775330 
	C82.137924,99.699066 81.568527,99.855003 80.999130,100.010941 
M76.003654,83.036072 
	C76.003654,83.036072 76.132172,83.092148 75.337624,83.082893 
	C74.593513,85.192329 73.235939,87.342850 73.373276,89.393242 
	C73.438469,90.366631 76.352455,91.873093 77.982231,91.881340 
	C82.768974,91.905563 87.569832,91.396278 92.337814,90.855080 
	C93.222954,90.754601 94.797890,89.245888 94.667343,88.873909 
	C93.675949,86.048920 93.063606,82.222069 90.946877,80.856934 
	C87.447113,78.599846 82.883446,77.011536 78.575516,81.028625 
	C78.575516,81.028625 78.216660,81.042061 77.500114,81.060287 
	C77.032295,81.753860 76.564476,82.447433 76.003654,83.036072 
z"/>
<path fill="#F8B69F" opacity="1.000000" stroke="none" 
	d="
M75.540443,116.940369 
	C76.844048,116.608704 78.888168,116.001854 79.366768,116.615677 
	C81.605743,119.487160 83.413277,122.695053 85.443420,126.126709 
	C84.483292,126.765701 83.454712,127.060730 81.880737,127.672134 
	C80.941162,127.977631 80.546967,127.966759 79.863205,127.755814 
	C78.780930,126.316444 77.988235,125.077148 77.195541,123.837845 
	C77.195541,123.837845 77.079071,123.853065 77.214813,123.551682 
	C76.925201,122.651443 76.499840,122.052605 76.074478,121.453758 
	C75.902901,120.045013 75.731323,118.636276 75.540443,116.940369 
M81.347221,122.479431 
	C81.347221,122.479431 81.570251,122.598801 81.570251,122.598801 
	C81.570251,122.598801 81.531601,122.347038 81.347221,122.479431 
z"/>
<path fill="#9C3525" opacity="1.000000" stroke="none" 
	d="
M77.096207,124.187790 
	C77.988235,125.077148 78.780930,126.316444 79.831413,128.162399 
	C79.679855,131.149109 79.270500,133.529144 78.861153,135.909195 
	C77.667976,134.651306 76.474800,133.393402 75.011810,132.053284 
	C74.626595,131.580795 74.511177,131.190536 74.688713,130.382187 
	C75.292618,129.039963 75.603569,128.115829 75.914513,127.191704 
	C76.275299,126.307045 76.636093,125.422386 77.096207,124.187790 
z"/>
<path fill="#C47161" opacity="1.000000" stroke="none" 
	d="
M75.580292,127.086823 
	C75.603569,128.115829 75.292618,129.039963 74.682892,130.076202 
	C73.621017,129.053146 72.857933,127.917984 72.007401,126.459206 
	C73.028671,126.417694 74.137367,126.699821 75.580292,127.086823 
z"/>
<path fill="#9C3525" opacity="1.000000" stroke="none" 
	d="
M76.073593,121.819687 
	C76.499840,122.052605 76.925201,122.651443 77.217010,123.610367 
	C76.746536,123.375504 76.409630,122.780556 76.073593,121.819687 
z"/>
<path fill="#EF763D" opacity="1.000000" stroke="none" 
	d="
M41.007362,151.996902 
	C41.740490,153.614548 42.473621,155.232178 43.552654,156.912659 
	C44.537411,158.662598 45.176266,160.349716 45.970905,162.329254 
	C45.771812,163.842636 45.416927,165.063583 45.008026,166.660034 
	C43.892056,165.776978 42.830105,164.518402 41.531517,162.979370 
	C40.001209,163.926376 38.378258,164.930725 36.381790,165.547470 
	C35.937328,163.707108 35.866386,162.254333 35.795444,160.801559 
	C35.804382,159.466385 35.813324,158.131226 36.167843,156.360626 
	C38.011402,154.615784 39.509384,153.306335 41.007362,151.996902 
z"/>
<path fill="#DC9A89" opacity="1.000000" stroke="none" 
	d="
M78.002838,152.267914 
	C78.113075,152.293488 78.234169,152.531235 78.193710,152.836823 
	C78.032173,152.904648 78.013680,152.480118 78.002838,152.267914 
z"/>
<path fill="#EF763D" opacity="1.000000" stroke="none" 
	d="
M45.920368,170.745590 
	C45.454189,170.476593 44.995045,169.901169 44.760445,169.152481 
	C45.299133,169.465866 45.613266,169.952515 45.920368,170.745590 
z"/>
<path fill="#F2BEAC" opacity="1.000000" stroke="none" 
	d="
M78.924591,136.313873 
	C79.270500,133.529144 79.679855,131.149109 80.120987,128.362473 
	C80.546967,127.966759 80.941162,127.977631 81.630028,127.982513 
	C82.250183,133.000687 82.575661,138.024872 82.870621,143.464722 
	C82.160767,143.931549 81.481438,143.982666 80.462936,144.057800 
	C79.926247,142.871933 79.728737,141.662033 79.247337,140.220947 
	C78.971642,138.899338 78.979836,137.808945 78.924591,136.313873 
z"/>
<path fill="#A5503F" opacity="1.000000" stroke="none" 
	d="
M15.024738,140.334351 
	C15.677644,138.541077 16.360458,137.065536 17.092545,135.206207 
	C21.159801,134.830460 19.138844,137.432724 18.978615,139.570282 
	C17.637390,140.168259 16.346020,140.410156 15.024738,140.334351 
z"/>
<path fill="#FEFEF9" opacity="1.000000" stroke="none" 
	d="
M180.304749,87.190475 
	C187.481689,84.550873 190.740082,85.918190 193.665192,92.378120 
	C196.154175,97.874893 194.230026,105.993118 189.843277,108.503273 
	C186.100540,110.644920 178.216385,106.662979 178.142334,101.992470 
	C178.065826,97.167152 179.328140,92.320618 180.304749,87.190475 
z"/>
<path fill="#D38C72" opacity="1.000000" stroke="none" 
	d="
M175.906525,145.903091 
	C173.271042,146.224625 171.555847,145.722443 172.815384,142.143921 
	C174.617722,136.832855 172.518936,132.226898 171.590195,127.133179 
	C174.820724,126.503845 177.999420,126.266319 181.588806,126.355484 
	C182.041565,129.796280 182.083618,132.910385 181.984253,136.249084 
	C181.560486,136.678131 181.278152,136.882599 180.760193,137.144379 
	C180.228516,137.373062 180.033188,137.623154 179.658752,138.130066 
	C178.221436,140.839798 177.063980,143.371445 175.906525,145.903091 
M173.926910,131.342392 
	C174.453781,131.730255 174.980667,132.118134 175.507538,132.505997 
	C175.507538,131.167755 175.507538,129.829514 175.507538,128.491272 
	C175.108200,128.487991 174.708878,128.484711 174.309540,128.481445 
	C174.162186,129.187515 174.014816,129.893570 173.926910,131.342392 
z"/>
<path fill="#F1C49B" opacity="1.000000" stroke="none" 
	d="
M182.125671,136.024490 
	C182.083618,132.910385 182.041565,129.796280 181.926926,126.306305 
	C182.663467,124.568542 183.472595,123.206642 184.631256,121.702309 
	C185.249802,121.839745 185.518814,122.119629 185.931900,122.649414 
	C186.075989,122.899330 186.004089,123.003616 185.697266,123.241699 
	C185.058411,124.979065 184.777710,126.492027 184.383087,127.974670 
	C183.666367,130.667450 182.881882,133.342209 182.125671,136.024490 
z"/>
<path fill="#B4EF65" opacity="1.000000" stroke="none" 
	d="
M170.047348,124.255203 
	C169.737656,124.181000 169.387527,123.866226 169.019836,123.290558 
	C169.337143,123.357986 169.672028,123.686302 170.047348,124.255203 
z"/>
<path fill="#F53412" opacity="1.000000" stroke="none" 
	d="
M107.223724,113.896408 
	C104.258636,116.240311 101.727707,118.897568 98.665070,120.596115 
	C88.998245,125.957359 88.151550,125.595276 82.888687,116.735756 
	C86.811714,114.332283 90.698502,111.951019 94.730560,108.981056 
	C94.901894,108.212959 94.927956,108.033569 95.334518,107.736649 
	C98.147820,107.386551 100.580620,107.153999 103.380684,106.949638 
	C104.483475,107.019653 105.218994,107.061478 106.074203,107.296707 
	C106.395325,107.735268 106.652199,107.885941 106.984383,107.966499 
	C107.004257,107.990875 107.053253,108.030327 107.035553,108.408859 
	C107.289291,110.184471 107.560722,111.581566 107.832153,112.978653 
	C107.795876,113.297028 107.656677,113.561150 107.223724,113.896408 
z"/>
<path fill="#FBE5DB" opacity="1.000000" stroke="none" 
	d="
M92.079453,109.010956 
	C87.370316,114.277100 79.696968,113.768494 76.150589,107.955139 
	C73.831024,104.152832 74.583092,102.541588 79.026604,102.328072 
	C88.320564,101.881485 97.621315,101.575905 107.368011,101.269623 
	C107.860985,102.569687 107.905220,103.812523 107.524940,105.472290 
	C105.766724,105.832626 104.433022,105.776047 102.814629,105.553284 
	C101.659897,105.353264 100.789856,105.319427 99.504837,105.191040 
	C96.153397,105.327385 91.943100,103.276978 92.079453,109.010956 
z"/>
<path fill="#CA998A" opacity="1.000000" stroke="none" 
	d="
M114.525917,120.048477 
	C114.485222,114.692032 116.335205,113.677658 120.893433,115.973991 
	C122.009377,116.536179 123.685242,115.986931 125.560471,115.758095 
	C127.356361,117.350288 128.695465,119.133057 129.799469,121.089096 
	C130.075745,122.177925 130.587143,123.093468 131.301605,124.100418 
	C131.759476,124.359909 131.952621,124.581680 131.930405,125.119339 
	C131.175827,125.896927 130.574982,126.412300 129.720825,126.932938 
	C129.298782,126.926704 129.130081,126.915215 128.817963,126.686523 
	C128.181915,126.360992 127.689247,126.252655 127.161896,125.746773 
	C124.911186,123.740105 122.695175,122.130989 120.037086,120.200867 
	C120.037086,125.161957 120.122498,128.786194 119.931923,132.395844 
	C119.907356,132.861313 118.452881,133.251282 117.660782,133.676224 
	C117.550903,130.654633 117.441032,127.633049 117.196838,124.216156 
	C118.676361,121.175461 116.499382,120.781693 114.525917,120.048477 
z"/>
<path fill="#B94F3D" opacity="1.000000" stroke="none" 
	d="
M103.099319,105.719475 
	C104.433022,105.776047 105.766724,105.832626 107.501495,105.849396 
	C108.488373,106.766884 109.074181,107.724167 109.172348,108.790306 
	C108.140884,108.609543 107.597069,108.319939 107.053253,108.030334 
	C107.053253,108.030327 107.004257,107.990875 106.881027,107.759399 
	C106.551895,107.269463 106.284134,107.127922 105.954514,107.103302 
	C105.218994,107.061478 104.483475,107.019653 103.396378,106.649170 
	C103.062965,106.120163 103.081139,105.919815 103.099319,105.719475 
z"/>
<path fill="#CF4C32" opacity="1.000000" stroke="none" 
	d="
M107.035553,108.408859 
	C107.597069,108.319939 108.140884,108.609543 108.974487,109.024475 
	C109.004272,110.314575 108.744278,111.479347 108.158218,112.811386 
	C107.560722,111.581566 107.289291,110.184471 107.035553,108.408859 
z"/>
<path fill="#FADAC4" opacity="1.000000" stroke="none" 
	d="
M120.421036,161.006042 
	C120.370110,157.970230 120.688057,154.925964 121.018234,151.066833 
	C120.583969,149.104111 120.137474,147.956253 119.690987,146.808411 
	C119.704208,146.465164 119.717422,146.121918 119.821350,145.059570 
	C119.949242,141.902496 119.986427,139.464523 120.425430,137.019562 
	C125.072121,136.746231 127.535927,138.521545 128.096466,142.929535 
	C128.235001,144.018875 129.340698,144.985214 130.210205,146.109894 
	C130.682022,146.396698 130.858047,146.643936 130.587982,147.000366 
	C125.004242,149.554306 124.919151,150.173904 128.997864,156.013809 
	C128.997864,156.996262 128.997864,157.978714 128.997864,157.860413 
	C125.799744,159.082779 123.294823,160.040207 120.421036,161.006042 
z"/>
<path fill="#FAF3EE" opacity="1.000000" stroke="none" 
	d="
M135.017899,122.989716 
	C140.055634,125.189491 140.272430,129.549088 140.043121,134.924011 
	C142.880753,141.522720 135.431961,143.528275 135.380096,148.071701 
	C136.951096,149.233383 138.550858,150.137787 139.752228,151.416504 
	C140.400131,152.106140 140.847885,153.656586 140.501495,154.414383 
	C140.169037,155.141708 138.360306,155.984634 137.775803,155.678375 
	C133.899994,153.647614 133.427429,157.062286 132.005951,159.001663 
	C131.992844,158.995010 131.966690,158.978287 131.966690,158.978287 
	C131.634842,158.178024 131.302979,157.377762 130.959152,155.859436 
	C131.294769,153.091095 131.642365,151.040833 132.148987,148.671585 
	C133.873154,144.234985 135.438309,140.117340 137.299332,135.749023 
	C137.719177,133.664703 137.843140,131.831070 137.855743,129.669037 
	C136.835556,127.223663 135.926727,125.106689 135.017899,122.989716 
z"/>
<path fill="#EFCEB1" opacity="1.000000" stroke="none" 
	d="
M131.989960,148.990555 
	C131.642365,151.040833 131.294769,153.091095 130.914001,155.535828 
	C130.462936,155.950668 130.045074,155.971085 129.312531,156.002655 
	C124.919151,150.173904 125.004242,149.554306 130.624435,147.303314 
	C131.343033,148.036377 131.666504,148.513458 131.989960,148.990555 
z"/>
<path fill="#FAF3EE" opacity="1.000000" stroke="none" 
	d="
M133.097427,164.338547 
	C133.097427,164.338547 133.107162,164.697037 133.060944,164.855286 
	C133.014725,165.013519 133.029785,164.954468 133.029785,164.954468 
	C132.361649,165.747665 131.693497,166.540863 131.054794,167.653503 
	C130.586868,166.799438 129.590805,165.573486 129.707428,164.464554 
	C129.888443,162.743423 130.827423,161.102020 131.705872,159.202545 
	C131.966690,158.978287 131.992844,158.995010 132.155777,159.359848 
	C132.578278,161.262634 132.837860,162.800598 133.097427,164.338547 
z"/>
<path fill="#955067" opacity="1.000000" stroke="none" 
	d="
M119.989922,168.999130 
	C119.285095,169.221649 118.580269,169.444168 117.868713,169.119019 
	C117.294098,168.447479 116.726196,168.323593 116.110451,167.901764 
	C116.039169,167.403961 116.015747,167.204132 116.002396,166.703873 
	C116.040131,166.204956 116.067795,166.006470 116.095451,165.807983 
	C117.976257,165.137589 120.600830,161.989716 120.785965,167.567261 
	C120.854424,167.938950 120.939590,167.924362 120.663101,167.957062 
	C120.254379,168.326202 120.122154,168.662674 119.989922,168.999130 
z"/>
<path fill="#843C53" opacity="1.000000" stroke="none" 
	d="
M115.920120,165.544678 
	C116.067795,166.006470 116.040131,166.204956 115.714127,166.711166 
	C115.223885,167.031723 115.031982,167.044571 114.523148,166.968231 
	C113.793503,166.740829 113.380783,166.602615 112.968056,166.464417 
	C112.968056,166.464417 112.942123,166.432419 112.948959,166.457245 
	C113.009918,166.279068 113.064056,166.076065 113.190445,165.566589 
	C114.090057,165.267197 114.917419,165.274277 115.920120,165.544678 
z"/>
<path fill="#DDAFC0" opacity="1.000000" stroke="none" 
	d="
M122.306808,169.034119 
	C121.851738,169.062546 121.684074,169.046005 121.252457,168.745422 
	C120.972206,168.282349 120.955894,168.103348 120.939590,167.924362 
	C120.939590,167.924362 120.854424,167.938950 120.824409,167.908691 
	C121.728409,167.984512 122.662430,168.090591 123.596443,168.196686 
	C123.262360,168.460831 122.928276,168.724991 122.306808,169.034119 
z"/>
<path fill="#683D43" opacity="1.000000" stroke="none" 
	d="
M113.446968,171.870514 
	C113.148064,171.856140 113.096321,171.478119 113.304344,171.049332 
	C113.881302,169.843842 114.250229,169.067139 114.748810,168.760635 
	C114.950714,169.547867 115.022987,169.864883 115.095268,170.181915 
	C114.645470,170.749573 114.195671,171.317245 113.446968,171.870514 
z"/>
<path fill="#7C3A52" opacity="1.000000" stroke="none" 
	d="
M115.293686,170.021271 
	C115.022987,169.864883 114.950714,169.547867 114.754883,168.554138 
	C114.678581,167.598434 114.748169,167.325089 114.840073,167.057419 
	C115.031982,167.044571 115.223885,167.031723 115.704048,167.011597 
	C116.015747,167.204132 116.039169,167.403961 116.077484,168.109497 
	C116.092361,168.615219 116.093643,169.036499 116.093643,169.036499 
	C115.893127,169.311203 115.692619,169.585907 115.293686,170.021271 
z"/>
<path fill="#E19FB6" opacity="1.000000" stroke="none" 
	d="
M116.345795,169.030670 
	C116.093643,169.036499 116.092361,168.615219 116.125336,168.407471 
	C116.726196,168.323593 117.294098,168.447479 117.732193,168.839661 
	C117.267570,169.080261 116.932755,169.052551 116.345795,169.030670 
z"/>
<path fill="#F3CFD9" opacity="1.000000" stroke="none" 
	d="
M120.663101,167.957062 
	C120.955894,168.103348 120.972206,168.282349 121.000160,168.730743 
	C120.841537,169.001770 120.671272,169.003403 120.245468,169.002075 
	C120.122154,168.662674 120.254379,168.326202 120.663101,167.957062 
z"/>
<path fill="#F1B898" opacity="1.000000" stroke="none" 
	d="
M120.023605,137.026535 
	C119.986427,139.464523 119.949242,141.902496 119.828537,144.802094 
	C118.765579,142.659302 117.786140,140.054932 116.515625,137.248459 
	C116.565796,136.080048 116.907043,135.113739 117.454529,133.911819 
	C118.452881,133.251282 119.907356,132.861313 119.931923,132.395844 
	C120.122498,128.786194 120.037086,125.161957 120.037086,120.200867 
	C122.695175,122.130989 124.911186,123.740105 126.933624,125.986877 
	C126.824608,127.748512 126.909172,128.872498 126.995941,130.371460 
	C126.997116,131.496399 126.996094,132.246368 126.630394,132.991882 
	C122.113022,131.131699 121.538361,134.805542 120.023605,137.026535 
z"/>
<path fill="#F1B898" opacity="1.000000" stroke="none" 
	d="
M119.486015,147.062378 
	C120.137474,147.956253 120.583969,149.104111 121.018951,150.626450 
	C120.549156,150.720642 120.090874,150.440323 119.402618,150.023438 
	C119.208786,149.030029 119.244911,148.173187 119.486015,147.062378 
z"/>
<path fill="#8C8289" opacity="1.000000" stroke="none" 
	d="
M103.499649,120.482925 
	C103.450638,120.418205 103.569092,120.311157 103.572021,120.349289 
	C103.574959,120.387421 103.548668,120.547646 103.499649,120.482925 
z"/>
<path fill="#DCA1B2" opacity="1.000000" stroke="none" 
	d="
M114.523148,166.968231 
	C114.748169,167.325089 114.678581,167.598434 114.625237,168.083954 
	C114.250229,169.067139 113.881302,169.843842 113.301666,170.858582 
	C112.907936,170.814713 112.724892,170.532822 112.310852,170.147217 
	C112.330299,169.093903 112.580742,168.144318 112.899620,166.829559 
	C113.380783,166.602615 113.793503,166.740829 114.523148,166.968231 
z"/>
<path fill="#B94F3D" opacity="1.000000" stroke="none" 
	d="
M92.414658,109.006332 
	C91.943100,103.276978 96.153397,105.327385 99.235489,105.405350 
	C97.905418,106.427528 96.429718,107.140862 94.954018,107.854187 
	C94.927956,108.033569 94.901894,108.212959 94.805725,108.655121 
	C94.073700,108.945816 93.411781,108.973763 92.414658,109.006332 
z"/>
<path fill="#F13719" opacity="1.000000" stroke="none" 
	d="
M92.170959,100.213600 
	C92.486855,99.669098 92.967514,99.356689 93.752594,99.038071 
	C93.483261,99.503136 92.909492,99.974411 92.170959,100.213600 
z"/>
<path fill="#FAF5CD" opacity="1.000000" stroke="none" 
	d="
M104.241898,74.261299 
	C104.748436,74.536766 105.316101,75.098976 105.848083,75.998184 
	C105.309265,75.739471 104.806152,75.143761 104.241898,74.261299 
z"/>
<path fill="#F13719" opacity="1.000000" stroke="none" 
	d="
M73.386612,101.087227 
	C73.822128,100.747772 74.592751,100.382103 75.713211,100.030411 
	C75.282593,100.383270 74.502151,100.722145 73.386612,101.087227 
z"/>
<path fill="#F13719" opacity="1.000000" stroke="none" 
	d="
M81.332184,100.017319 
	C81.568527,99.855003 82.137924,99.699066 82.852196,99.769958 
	C82.553123,100.005760 82.109184,100.014725 81.332184,100.017319 
z"/>
<path fill="#D2ED6E" opacity="1.000000" stroke="none" 
	d="
M66.176491,70.856232 
	C65.930763,70.812592 65.786263,70.492355 65.848007,70.103355 
	C66.128738,70.216270 66.203232,70.397957 66.176491,70.856232 
z"/>
<path fill="#9C7F1A" opacity="1.000000" stroke="none" 
	d="
M39.004074,124.850914 
	C38.928726,125.099998 38.514641,125.004799 38.311134,124.944580 
	C38.107628,121.825455 38.107628,118.766548 38.107628,114.686668 
	C40.840580,116.527740 42.762093,117.822182 44.398239,119.329163 
	C41.736656,117.469833 41.143162,118.704475 41.121609,121.126648 
	C41.110149,122.414391 40.995213,123.701218 40.926983,124.988457 
	C40.311131,124.859581 39.695274,124.730705 39.004074,124.850914 
z"/>
<path fill="#CC6736" opacity="1.000000" stroke="none" 
	d="
M40.828537,125.261757 
	C40.995213,123.701218 41.110149,122.414391 41.121609,121.126648 
	C41.143162,118.704475 41.736656,117.469833 44.348045,119.681519 
	C44.678070,120.000671 44.772919,120.179993 44.680885,120.837204 
	C44.652199,122.240974 44.810398,123.166847 44.955517,124.346405 
	C44.931114,124.769043 44.919788,124.938011 44.566475,125.131424 
	C43.422195,126.458252 42.619904,127.760651 41.817604,129.063049 
	C41.455097,127.887054 41.092594,126.711052 40.828537,125.261757 
z"/>
<path fill="#C07820" opacity="1.000000" stroke="none" 
	d="
M44.908463,125.106987 
	C44.919788,124.938011 44.931114,124.769043 45.292870,124.304161 
	C45.867310,123.975601 46.091316,123.942963 46.315327,123.910324 
	C46.315327,123.910324 46.702782,123.923874 46.888374,123.982925 
	C47.953205,124.857948 48.832447,125.673920 49.734722,127.007965 
	C49.178837,127.764687 48.599915,128.003342 47.680977,128.165512 
	C46.530128,127.095016 45.719296,126.100998 44.908463,125.106987 
z"/>
<path fill="#A26508" opacity="1.000000" stroke="none" 
	d="
M46.287567,123.571564 
	C46.091316,123.942963 45.867310,123.975601 45.305946,124.050484 
	C44.810398,123.166847 44.652199,122.240974 44.754387,121.132492 
	C45.429783,121.710861 45.844795,122.471832 46.287567,123.571564 
z"/>
<path fill="#D7632F" opacity="1.000000" stroke="none" 
	d="
M41.006649,151.547501 
	C39.509384,153.306335 38.011402,154.615784 36.146973,155.960266 
	C35.332397,152.196548 32.298210,148.184906 37.168514,144.819519 
	C37.891094,144.320221 37.049915,141.557892 36.955578,139.049423 
	C36.983398,137.170715 36.987835,136.085114 36.992275,134.999512 
	C36.992275,134.999512 37.491089,134.985291 37.988228,134.988525 
	C38.485367,134.991745 38.980728,134.993118 39.018768,135.326141 
	C40.118042,135.945724 41.216381,136.547516 42.229839,136.428238 
	C43.200001,136.314041 44.081974,135.450607 45.002708,134.916473 
	C45.005047,136.094025 45.479240,137.974457 44.935925,138.339188 
	C40.089577,141.592560 40.988712,146.453873 41.006649,151.547501 
z"/>
<path fill="#D7632F" opacity="1.000000" stroke="none" 
	d="
M35.447250,160.941132 
	C35.866386,162.254333 35.937328,163.707108 36.028755,165.593124 
	C36.042694,166.762863 36.036152,167.499359 36.042885,168.603607 
	C35.365944,171.599564 34.675728,174.227768 33.657219,176.988403 
	C33.918968,171.774109 34.509014,166.427414 35.447250,160.941132 
z"/>
<path fill="#CC6736" opacity="1.000000" stroke="none" 
	d="
M36.675236,135.136063 
	C36.987835,136.085114 36.983398,137.170715 36.955559,138.665466 
	C36.191513,139.052063 35.450871,139.029495 34.337845,138.995972 
	C34.763042,137.747528 35.560619,136.510071 36.675236,135.136063 
z"/>
<path fill="#F67B45" opacity="1.000000" stroke="none" 
	d="
M45.160297,134.650818 
	C44.081974,135.450607 43.200001,136.314041 42.229839,136.428238 
	C41.216381,136.547516 40.118042,135.945724 39.240128,135.219223 
	C39.702362,134.578110 39.879097,134.309647 39.953648,133.973907 
	C39.953648,133.973907 39.995461,133.984756 40.350403,133.928238 
	C41.076778,132.834473 41.448208,131.797226 41.819633,130.759979 
	C41.816975,130.382584 41.814316,130.005203 41.814632,129.345428 
	C42.619904,127.760651 43.422195,126.458252 44.566475,125.131424 
	C45.719296,126.100998 46.530128,127.095016 47.672916,128.514404 
	C47.999828,129.647369 47.994781,130.354965 47.989738,131.062561 
	C47.099117,132.170090 46.208504,133.277634 45.160297,134.650818 
z"/>
<path fill="#CC6736" opacity="1.000000" stroke="none" 
	d="
M41.551758,130.812866 
	C41.448208,131.797226 41.076778,132.834473 40.338516,133.910126 
	C39.968407,133.445145 39.965130,132.941742 39.939453,132.056610 
	C40.372665,131.405197 40.828270,131.135483 41.551758,130.812866 
z"/>
<path fill="#CC6736" opacity="1.000000" stroke="none" 
	d="
M39.661770,134.000244 
	C39.879097,134.309647 39.702362,134.578110 39.202084,134.886200 
	C38.980728,134.993118 38.485367,134.991745 38.237709,134.984924 
	C38.450001,134.660919 38.909946,134.343765 39.661770,134.000244 
z"/>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M133.429626,164.272385 
	C132.837860,162.800598 132.578278,161.262634 132.168884,159.366501 
	C133.427429,157.062286 133.899994,153.647614 137.775803,155.678375 
	C138.360306,155.984634 140.169037,155.141708 140.501495,154.414383 
	C140.847885,153.656586 140.400131,152.106140 139.752228,151.416504 
	C138.550858,150.137787 136.951096,149.233383 135.380096,148.071701 
	C135.431961,143.528275 142.880753,141.522720 140.044113,135.359207 
	C140.749100,134.686890 142.061264,134.487625 142.144135,134.053635 
	C143.315903,127.917007 148.016418,128.870285 152.191055,129.034012 
	C156.790237,129.214401 161.374557,129.763016 165.973526,129.963318 
	C169.420792,130.113480 170.072891,132.190720 169.807907,134.981918 
	C169.684677,136.279984 169.136871,137.540009 169.033630,138.837341 
	C168.318771,147.821396 167.736526,156.816788 166.916077,165.790649 
	C166.814438,166.902405 165.559540,167.908707 164.164230,168.976715 
	C163.316574,168.990723 163.143738,168.991882 162.970917,168.993057 
	C162.533188,168.994553 162.095474,168.996048 161.329483,168.998474 
	C159.286163,168.998840 157.571106,168.998291 155.297852,168.690094 
	C154.512115,166.681305 154.284592,164.980133 154.057068,163.278976 
	C153.649368,163.196716 153.241684,163.114456 152.833984,163.032196 
	C151.554901,165.018814 150.275833,167.005447 148.996765,168.992065 
	C148.248672,168.993088 147.500595,168.994110 146.378601,168.991211 
	C144.979691,164.067352 140.807083,165.692764 137.315674,165.024872 
	C135.846497,164.759216 134.804153,164.482727 133.429626,164.272385 
z"/>
<path fill="#EFEFEF" opacity="1.000000" stroke="none" 
	d="
M163.083130,169.220093 
	C163.143738,168.991882 163.316574,168.990723 163.748779,168.990967 
	C164.009338,169.161179 164.010559,169.329971 164.016373,169.751999 
	C163.745758,169.819199 163.470551,169.633179 163.083130,169.220093 
z"/>
<path fill="#FAF9FB" opacity="1.000000" stroke="none" 
	d="
M149.346710,168.998322 
	C150.275833,167.005447 151.554901,165.018814 152.833984,163.032196 
	C153.241684,163.114456 153.649368,163.196716 154.057068,163.278976 
	C154.284592,164.980133 154.512115,166.681305 154.869232,168.689392 
	C154.997116,169.442139 154.995392,169.887924 154.994705,170.668091 
	C154.557693,171.002304 154.119644,171.002182 153.353577,171.002716 
	C151.915924,170.337128 150.806290,169.670853 149.346710,168.998322 
z"/>
<path fill="#FDEFDB" opacity="1.000000" stroke="none" 
	d="
M177.316818,168.138641 
	C176.996902,167.556854 176.997467,167.111481 177.265839,166.111877 
	C177.032639,164.368759 176.531631,163.179871 176.168945,161.649597 
	C176.555710,159.868759 176.804153,158.429306 177.412704,156.988434 
	C179.511826,155.989532 181.250854,154.992035 183.153458,154.238785 
	C183.861679,154.661697 184.406311,154.840363 184.950958,155.019012 
	C184.958099,158.049423 184.965240,161.079849 184.973724,164.566406 
	C184.975052,165.022552 184.961655,165.495895 184.948395,165.731995 
	C182.502533,166.737091 180.069916,167.506058 177.316818,168.138641 
z"/>
<path fill="#F7DCBF" opacity="1.000000" stroke="none" 
	d="
M187.984314,148.987457 
	C187.226425,150.293945 186.468536,151.600433 185.347534,152.951218 
	C184.984421,152.995514 184.930862,152.978699 184.947479,152.582687 
	C184.981598,151.128799 184.999115,150.070908 185.387772,149.011032 
	C186.500732,149.001846 187.242523,148.994659 187.984314,148.987457 
z"/>
<path fill="#FDE7CE" opacity="1.000000" stroke="none" 
	d="
M187.986694,148.656830 
	C187.242523,148.994659 186.500732,149.001846 185.384399,148.656998 
	C185.659653,147.524750 186.309418,146.744537 186.959167,145.964310 
	C187.151077,146.233627 187.343002,146.502930 187.757324,146.887726 
	C187.982849,147.444183 187.985947,147.885193 187.986694,148.656830 
z"/>
<path fill="#FDE7CE" opacity="1.000000" stroke="none" 
	d="
M184.973511,154.684341 
	C184.406311,154.840363 183.861679,154.661697 183.315948,153.997009 
	C183.853531,153.333572 184.392197,153.156143 184.930862,152.978699 
	C184.930862,152.978699 184.984421,152.995514 185.012115,153.002563 
	C185.025223,153.456299 185.010635,153.902985 184.973511,154.684341 
z"/>
<path fill="#5BD4F6" opacity="1.000000" stroke="none" 
	d="
M141.346771,84.155655 
	C139.575806,83.504173 138.199646,82.856033 136.510086,82.148743 
	C135.288544,81.902847 134.380386,81.716110 133.161301,81.465439 
	C138.988220,78.066063 139.529205,75.734306 135.259949,72.108658 
	C138.226120,71.437485 141.560638,70.464806 144.859329,70.574043 
	C147.335052,70.656029 149.764709,72.128914 152.600632,73.000618 
	C153.099075,73.333076 153.289856,73.589340 153.774963,73.897141 
	C154.758118,74.982658 155.525055,75.958473 156.625015,76.986549 
	C156.656830,80.649780 156.652527,84.316025 155.992905,87.860291 
	C154.599350,95.348015 155.017944,102.466484 157.505081,109.803177 
	C158.824661,113.695770 158.006699,118.350861 157.898041,122.655296 
	C157.878174,123.442253 156.657715,124.230644 155.920380,124.945000 
	C155.740540,125.119232 155.279694,125.017563 154.947952,125.018837 
	C142.141541,125.068199 144.066330,125.944252 144.021713,115.218307 
	C143.985413,106.490677 144.137054,97.759888 143.921249,89.037521 
	C143.880600,87.394379 142.502533,85.784325 141.346771,84.155655 
z"/>
<path fill="#EAC4B0" opacity="1.000000" stroke="none" 
	d="
M134.762421,122.834717 
	C135.926727,125.106689 136.835556,127.223663 137.464249,129.670441 
	C136.140381,129.996597 135.096634,129.992966 134.044403,129.595459 
	C133.385315,127.753426 132.734726,126.305283 132.084137,124.857132 
	C131.952621,124.581680 131.759476,124.359909 131.274963,123.756882 
	C130.708374,122.519897 130.371475,121.717857 130.034576,120.915817 
	C128.695465,119.133057 127.356361,117.350288 125.770294,115.459946 
	C125.460953,114.778435 125.398567,114.204506 125.531784,113.235146 
	C128.006226,114.381516 130.285080,115.923309 132.778915,118.068100 
	C133.349274,119.444855 133.704651,120.218620 134.060028,120.992393 
	C134.208984,121.554840 134.357956,122.117287 134.762421,122.834717 
z"/>
<path fill="#FAF3EE" opacity="1.000000" stroke="none" 
	d="
M134.305573,120.786270 
	C133.704651,120.218620 133.349274,119.444855 132.990295,118.329132 
	C133.508163,118.851509 134.029648,119.715836 134.305573,120.786270 
z"/>
<path fill="#E8C1B2" opacity="1.000000" stroke="none" 
	d="
M169.993362,154.254166 
	C170.130707,154.264832 170.270493,154.527496 170.261810,154.893188 
	C170.074158,154.832855 170.034973,154.669495 169.993362,154.254166 
z"/>
<path fill="#E4CDDA" opacity="1.000000" stroke="none" 
	d="
M132.984497,165.017487 
	C133.575821,165.559738 134.197342,166.098022 134.818878,166.636322 
	C134.391281,166.999542 133.963669,167.362762 133.536072,167.725983 
	C133.323318,167.050064 133.110565,166.374130 132.963806,165.326340 
	C133.029785,164.954468 133.014725,165.013519 132.984497,165.017487 
z"/>
<path fill="#F9C9B1" opacity="1.000000" stroke="none" 
	d="
M177.052597,156.989838 
	C176.804153,158.429306 176.555710,159.868759 176.153992,162.051727 
	C175.996414,163.868576 175.992096,164.941925 175.987793,166.015259 
	C175.229523,166.673752 174.471252,167.332245 173.362885,167.999329 
	C173.923859,162.470551 174.870331,156.938599 175.723450,151.392303 
	C175.965149,149.820877 175.934402,148.207535 175.967560,146.258255 
	C177.063980,143.371445 178.221436,140.839798 179.884109,138.052643 
	C180.674973,137.631729 180.877136,137.395035 180.995819,137.087067 
	C181.278152,136.882599 181.560486,136.678131 181.984238,136.249084 
	C182.881882,133.342209 183.666367,130.667450 184.383087,127.974670 
	C184.777710,126.492027 185.058411,124.979065 185.662598,123.294693 
	C186.594925,127.457535 187.255096,131.805466 187.969513,136.529327 
	C188.023727,136.905273 188.008652,136.953430 187.653778,136.972015 
	C186.200912,137.661911 185.102890,138.333237 183.788559,139.273926 
	C178.357864,141.722443 178.547699,146.542755 177.712158,151.195587 
	C177.313446,152.268524 177.179871,153.137390 177.033813,154.379242 
	C177.031738,155.498108 177.042160,156.243973 177.052597,156.989838 
z"/>
<path fill="#FDE7CE" opacity="1.000000" stroke="none" 
	d="
M176.241959,166.009857 
	C175.992096,164.941925 175.996414,163.868576 176.015686,162.393097 
	C176.531631,163.179871 177.032639,164.368759 177.269073,165.777878 
	C176.835037,166.000214 176.665573,166.002319 176.241959,166.009857 
z"/>
<path fill="#FEF5E2" opacity="1.000000" stroke="none" 
	d="
M177.977295,150.991516 
	C178.547699,146.542755 178.357864,141.722443 184.185822,139.274017 
	C185.857727,139.011719 186.916061,139.018677 187.974380,139.025620 
	C187.745270,141.103607 187.516159,143.181610 187.123108,145.611954 
	C186.309418,146.744537 185.659653,147.524750 185.013245,148.658997 
	C184.999115,150.070908 184.981598,151.128799 184.947479,152.582703 
	C184.392197,153.156143 183.853531,153.333572 183.152374,153.752777 
	C181.250854,154.992035 179.511826,155.989532 177.412704,156.988434 
	C177.042160,156.243973 177.031738,155.498108 177.354095,154.329819 
	C177.783676,152.935440 177.880478,151.963470 177.977295,150.991516 
z"/>
<path fill="#FDE7CE" opacity="1.000000" stroke="none" 
	d="
M187.984955,138.688477 
	C186.916061,139.018677 185.857727,139.011719 184.402130,139.004669 
	C185.102890,138.333237 186.200912,137.661911 187.649155,136.996826 
	C187.998093,137.452469 187.996811,137.901901 187.984955,138.688477 
z"/>
<path fill="#F2844D" opacity="1.000000" stroke="none" 
	d="
M48.197052,131.256256 
	C47.994781,130.354965 47.999828,129.647369 48.012932,128.590881 
	C48.599915,128.003342 49.178837,127.764687 49.946358,127.269669 
	C50.134964,127.013313 50.538357,127.012108 50.739437,127.012413 
	C50.095135,128.491806 49.249752,129.970886 48.197052,131.256256 
z"/>
<path fill="#FBE4D8" opacity="1.000000" stroke="none" 
	d="
M78.216660,81.042061 
	C78.216660,81.042061 78.575516,81.028625 79.128662,81.067635 
	C87.465034,79.625031 91.248077,81.813652 92.647018,89.278503 
	C86.675552,89.581505 80.805428,89.879364 73.594330,90.245270 
	C74.696014,87.140076 75.414093,85.116112 76.132172,83.092148 
	C76.132172,83.092148 76.003654,83.036072 76.353157,83.007965 
	C77.207329,82.333923 77.711990,81.687988 78.216660,81.042061 
z"/>
<path fill="#CA432A" opacity="1.000000" stroke="none" 
	d="
M75.734894,83.087524 
	C75.414093,85.116112 74.696014,87.140076 73.594330,90.245270 
	C80.805428,89.879364 86.675552,89.581505 92.647018,89.278503 
	C91.248077,81.813652 87.465034,79.625031 79.299538,81.012497 
	C82.883446,77.011536 87.447113,78.599846 90.946877,80.856934 
	C93.063606,82.222069 93.675949,86.048920 94.667343,88.873909 
	C94.797890,89.245888 93.222954,90.754601 92.337814,90.855080 
	C87.569832,91.396278 82.768974,91.905563 77.982231,91.881340 
	C76.352455,91.873093 73.438469,90.366631 73.373276,89.393242 
	C73.235939,87.342850 74.593513,85.192329 75.734894,83.087524 
z"/>
<path fill="#CA432A" opacity="1.000000" stroke="none" 
	d="
M77.858383,81.051178 
	C77.711990,81.687988 77.207329,82.333923 76.399658,83.060432 
	C76.564476,82.447433 77.032295,81.753860 77.858383,81.051178 
z"/>
<path fill="#FEF0DC" opacity="1.000000" stroke="none" 
	d="
M81.439407,122.413239 
	C81.531601,122.347038 81.570251,122.598801 81.570251,122.598801 
	C81.570251,122.598801 81.347221,122.479431 81.439407,122.413239 
z"/>
<path fill="#F1F9F4" opacity="1.000000" stroke="none" 
	d="
M173.897186,130.971008 
	C174.014816,129.893570 174.162186,129.187515 174.309540,128.481445 
	C174.708878,128.484711 175.108200,128.487991 175.507538,128.491272 
	C175.507538,129.829514 175.507538,131.167755 175.507538,132.505997 
	C174.980667,132.118134 174.453781,131.730255 173.897186,130.971008 
z"/>
<path fill="#F1C49B" opacity="1.000000" stroke="none" 
	d="
M180.760193,137.144379 
	C180.877136,137.395035 180.674973,137.631729 180.163971,137.874557 
	C180.033188,137.623154 180.228516,137.373062 180.760193,137.144379 
z"/>
<path fill="#CF4C32" opacity="1.000000" stroke="none" 
	d="
M95.334518,107.736649 
	C96.429718,107.140862 97.905418,106.427528 99.650467,105.499901 
	C100.789856,105.319427 101.659897,105.353264 102.814629,105.553284 
	C103.081139,105.919815 103.062965,106.120163 103.029099,106.620972 
	C100.580620,107.153999 98.147820,107.386551 95.334518,107.736649 
z"/>
<path fill="#CF4C32" opacity="1.000000" stroke="none" 
	d="
M106.074203,107.296707 
	C106.284134,107.127922 106.551895,107.269463 106.861145,107.735023 
	C106.652199,107.885941 106.395325,107.735268 106.074203,107.296707 
z"/>
<path fill="#F7B08B" opacity="1.000000" stroke="none" 
	d="
M131.930405,125.119339 
	C132.734726,126.305283 133.385315,127.753426 133.765442,129.809128 
	C132.142639,133.328384 130.206467,134.768921 126.995071,132.996338 
	C126.996094,132.246368 126.997116,131.496399 127.323082,130.356384 
	C128.083389,129.936584 128.518768,129.906860 129.202148,129.911682 
	C129.652267,129.576096 129.854370,129.205963 130.044800,128.518219 
	C130.013458,127.776291 129.993790,127.351982 129.974136,126.927666 
	C130.574982,126.412300 131.175827,125.896927 131.930405,125.119339 
z"/>
<path fill="#BA6E4E" opacity="1.000000" stroke="none" 
	d="
M128.954132,129.877136 
	C128.518768,129.906860 128.083389,129.936584 127.320885,129.981400 
	C126.909172,128.872498 126.824608,127.748512 126.968315,126.384430 
	C127.689247,126.252655 128.181915,126.360992 128.590942,126.852127 
	C128.658890,127.847969 128.810471,128.461014 128.968567,129.274918 
	C128.975082,129.475769 128.954132,129.877136 128.954132,129.877136 
z"/>
<path fill="#F7B08B" opacity="1.000000" stroke="none" 
	d="
M129.799469,121.089096 
	C130.371475,121.717857 130.708374,122.519897 131.071899,123.665482 
	C130.587143,123.093468 130.075745,122.177925 129.799469,121.089096 
z"/>
<path fill="#EFA07B" opacity="1.000000" stroke="none" 
	d="
M128.962067,129.074066 
	C128.810471,128.461014 128.658890,127.847969 128.734329,127.069321 
	C129.130081,126.915215 129.298782,126.926704 129.720825,126.932938 
	C129.993790,127.351982 130.013458,127.776291 129.772812,128.587311 
	C129.329025,129.007355 129.145554,129.040710 128.962067,129.074066 
z"/>
<path fill="#FCCEAE" opacity="1.000000" stroke="none" 
	d="
M126.630394,132.991882 
	C130.206467,134.768921 132.142639,133.328384 133.773926,130.203003 
	C135.096634,129.992966 136.140381,129.996597 137.575607,129.998825 
	C137.843140,131.831070 137.719177,133.664703 136.950317,135.802109 
	C134.137726,139.364273 129.055130,140.767120 130.001801,146.008102 
	C129.340698,144.985214 128.235001,144.018875 128.096466,142.929535 
	C127.535927,138.521545 125.072121,136.746231 120.425423,137.019562 
	C121.538361,134.805542 122.113022,131.131699 126.630394,132.991882 
z"/>
<path fill="#E9C0A9" opacity="1.000000" stroke="none" 
	d="
M130.210205,146.109894 
	C129.055130,140.767120 134.137726,139.364273 136.654449,136.052795 
	C135.438309,140.117340 133.873154,144.234985 132.148987,148.671585 
	C131.666504,148.513458 131.343033,148.036377 130.983124,147.256332 
	C130.858047,146.643936 130.682022,146.396698 130.210205,146.109894 
z"/>
<path fill="#FDEFDB" opacity="1.000000" stroke="none" 
	d="
M177.712143,151.195587 
	C177.880478,151.963470 177.783676,152.935440 177.366592,153.956818 
	C177.179871,153.137390 177.313446,152.268524 177.712143,151.195587 
z"/>
<path fill="#CC815B" opacity="1.000000" stroke="none" 
	d="
M128.968567,129.274918 
	C129.145554,129.040710 129.329025,129.007355 129.784485,128.904922 
	C129.854370,129.205963 129.652267,129.576096 129.202148,129.911682 
	C128.954132,129.877136 128.975082,129.475769 128.968567,129.274918 
z"/>
</svg>
  )
}

export default Logo