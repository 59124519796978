const productCategory = [
    { id : 1, label : "Airpods", value : "airpods"},
    { id : 2, label : "Security and Suveillance Cameras", value : "Security and Suveillance Cameras"},
    { id : 3, label : "Microphones", value : "Microphones"},
    { id : 4, label : "Walkie Talkies and Communication Devices", value : "Walkie Talkies and Communication Devices"},
    { id : 7, label : "Sun Glasses and Bluetooth Devices", value : "Sun Glasses and Bluetooth Devices"},
    { id : 8, label : "Wall Clocks and Stop Watches", value : "Wall Clocks and Stop Watches"},
    { id : 9, label : "Ring Lights & LED Lights", value : "Ring Lights & LED Lights"},
    { id : 10, label : "Tripod Stands", value : "Tripod Stands"},
    { id : 11, label : "Beauty Products", value : "Beauty Products"},
    { id : 12, label : "Watches", value : "watches"},
    { id : 13, label : "Hybrid Cars Parts", value : "Hybrid Cars Parts"},
    { id : 14, label : "Car Accessories", value : "Car Accessories"},
    { id : 15, label : "Home Decor", value : "Home Decor"},
    { id : 16, label : "Vehicles", value : "Vehicles"},
    { id : 17, label : "Photography and Camera Accessories", value : "Photography and Camera Accessories"},
    { id : 18, label : "Other Electronics", value : "Other Electronics"},



]


export default productCategory